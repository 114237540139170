import { ApiParamFields } from 'constants/api';

export const categoryKeys = {
  category: (categoryCode: string, fields: ApiParamFields = 'FULL') => ['category', categoryCode, fields],
  categoryInfoFromPath: (categoryPath: string) => ['categoryInfoFromPath', categoryPath],
  categoryLevel: (categoryCode: string) => ['categoryLevel', categoryCode],
  categorySubCategories: (categoryCode: string) => ['categorySubCategories', categoryCode],
  infoLinks: (categoryCode?: string) => ['categoryInfoLinks', categoryCode ?? ''],
  promotedBrands: (categoryCode?: string) => ['promotedBrands', categoryCode ?? ''],
  search: (
    categoryCode: string,
    currentPage?: string | number,
    pageSize?: string | number,
    query?: string | string[],
    sort?: string | string[],
    withContentTiles?: boolean
  ) => ['search', categoryCode, currentPage, pageSize, query, sort, withContentTiles],
  silo: (categoryCode: string) => ['categorySilo', categoryCode],
};
