import { skipToken, useQuery } from '@tanstack/react-query';
import useRouter from 'hooks/useRouter';
import { bundleKeys } from '.';
import { getProductCodeFromQuery } from '../../utils/productUtil';
import {
  FetchBundleAlternativeProductsParams,
  FetchBundlePriceParams,
  fetchBundleAlternativeProducts,
  fetchBundleByProductCode,
  fetchBundlePrice,
} from './connector';

const useBundle = (code?: string) => {
  const { query } = useRouter();
  const productCode = code ?? getProductCodeFromQuery(query) ?? '';

  return useQuery({
    queryFn: productCode ? () => fetchBundleByProductCode(productCode) : skipToken,
    queryKey: bundleKeys.bundleByProductCode(productCode),
    select: (data) => data?.bundles,
  });
};

const useBundlePrice = ({
  bundleCode,
  selectedAccessoriesProductCodes,
}: Omit<FetchBundlePriceParams, 'baseProductCode'>) => {
  const { query } = useRouter();
  const baseProductCode = getProductCodeFromQuery(query) ?? '';

  return useQuery({
    queryFn: () => fetchBundlePrice({ baseProductCode, bundleCode, selectedAccessoriesProductCodes }),
    queryKey: bundleKeys.bundlePrice({ baseProductCode, bundleCode, selectedAccessoriesProductCodes }),
  });
};

const useBundlesPartProductAlternatives = ({
  enabled = false,
  partCode,
  productCode,
}: FetchBundleAlternativeProductsParams & { enabled?: boolean }) =>
  useQuery({
    enabled,
    queryFn: () => fetchBundleAlternativeProducts({ partCode, productCode }),
    queryKey: bundleKeys.bundlePartProductAlternatives({ partCode, productCode }),
    select: (data) => data?.products,
  });

export { useBundle, useBundlePrice, useBundlesPartProductAlternatives };
