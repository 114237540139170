import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { API_PARAM_FIELDS, ApiParamFields } from 'constants/api';
import { useWebConfig } from 'features/configuration/queries';
import useRouter from 'hooks/useRouter';
import { Category } from 'types/Category';
import { categoryKeys } from '.';
import { DEFAULT_QUERY, NUMBER_OF_PAGE_RESULTS } from '../../constants/search';
import {
  getCategory,
  getCategoryInfoLinks,
  getCategoryInfoUsingPath,
  getCategorySilo,
  getCategorySubCategories,
  getPromotedBrandsByCategory,
  searchCategory,
} from './connector';

const useCategoryInfoFromPath = (enabled = true) => {
  const {
    query: { slug },
  } = useRouter();

  const categoryPath = Array.isArray(slug) ? slug.join('/') : slug ?? '';

  return useQuery({
    enabled: enabled && !!categoryPath,
    placeholderData: keepPreviousData,
    queryFn: () => getCategoryInfoUsingPath(categoryPath),
    queryKey: categoryKeys.categoryInfoFromPath(categoryPath),
    refetchOnWindowFocus: false,
  });
};

const useCategory = (
  categoryCode: string,
  categoryLevel: number,
  fieldsParam: ApiParamFields = API_PARAM_FIELDS.FULL,
) => {
  const isL2 = categoryLevel === 2;

  const fields = isL2 ? API_PARAM_FIELDS.L2 : fieldsParam;

  return useQuery({
    enabled: !!categoryCode,
    placeholderData: keepPreviousData,
    queryFn: () => getCategory(categoryCode, fields),
    queryKey: categoryKeys.category(categoryCode, fields),
    refetchOnWindowFocus: false,
  });
};

const useCategorySubCategories = (categoryCode: string) =>
  useQuery({
    enabled: !!categoryCode,
    placeholderData: keepPreviousData,
    queryFn: () => getCategorySubCategories(categoryCode),
    queryKey: categoryKeys.categorySubCategories(categoryCode),
    refetchOnWindowFocus: false,
  });

const useCategorySearch = (category: Category | undefined) => {
  const { isReady, query } = useRouter();
  const { currentPage, pageSize, q, sort } = query || {};

  return useQuery({
    enabled: isReady && !!category?.code,
    placeholderData: keepPreviousData,
    queryFn: async () =>
      searchCategory(
        category?.code ?? '',
        Number(currentPage) || 0,
        Number(pageSize) || NUMBER_OF_PAGE_RESULTS.TOTAL_RESULTS_1,
        q || DEFAULT_QUERY,
        sort ?? '',
        !!category?.plpContentTiles?.length,
      ),
    queryKey: categoryKeys.search(
      category?.code ?? '',
      Number(currentPage) || 0,
      Number(pageSize) || NUMBER_OF_PAGE_RESULTS.TOTAL_RESULTS_1,
      q || DEFAULT_QUERY,
      sort ?? '',
      !!category?.plpContentTiles?.length,
    ),
    refetchOnWindowFocus: false,
  });
};

const useCategorySilo = (categoryCode: string) =>
  useQuery({
    enabled: !!categoryCode,
    placeholderData: keepPreviousData,
    queryFn: () => getCategorySilo(categoryCode),
    queryKey: categoryKeys.silo(categoryCode),
    refetchOnWindowFocus: false,
  });

const useCategoryInfoLinks = (categoryCode?: string) =>
  useQuery({
    enabled: !!categoryCode,
    placeholderData: keepPreviousData,
    queryFn: () => getCategoryInfoLinks(categoryCode),
    queryKey: categoryKeys.infoLinks(categoryCode),
    refetchOnWindowFocus: false,
  });

const usePromotedBrands = (categoryCode?: string) => {
  const { data: webConfig } = useWebConfig();

  return useQuery({
    enabled: !!categoryCode && !!webConfig?.enablePromotedBrands,
    placeholderData: keepPreviousData,
    queryFn: () => getPromotedBrandsByCategory(categoryCode),
    queryKey: categoryKeys.promotedBrands(categoryCode),
    refetchOnWindowFocus: false,
    select: (data) => data?.brands || [],
  });
};

export {
  useCategory,
  useCategoryInfoFromPath,
  useCategoryInfoLinks,
  useCategorySearch,
  useCategorySilo,
  useCategorySubCategories,
  usePromotedBrands,
};
