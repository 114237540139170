import { Bundle, BundleAlternativeProducts, BundlePrice } from 'types/Bundle';
import { apiClient } from '../../helpers/ApiClient';
import { logErrorResponse } from '../../utils/loggerUtil';

const fetchBundleByProductCode = async (productCode: string) => {
  try {
    const { data } = await apiClient<{ bundles?: Bundle[] }>({
      params: {
        fields: 'FULL',
      },
      url: `/bundle/${productCode}`,
    });

    return data;
  } catch (error) {
    logErrorResponse('Bundle Connector', error, `Error fetching bundle for product with code ${productCode}`);
    throw error;
  }
};

export type FetchBundlePriceParams = {
  baseProductCode: string;
  bundleCode: string;
  selectedAccessoriesProductCodes?: string[];
};

const fetchBundlePrice = async ({
  baseProductCode,
  bundleCode,
  selectedAccessoriesProductCodes,
}: FetchBundlePriceParams) => {
  try {
    const { data } = await apiClient<BundlePrice>({
      params: {
        baseProduct: baseProductCode,
        bundleCode,
        fields: 'FULL',
        selectedAccessories: selectedAccessoriesProductCodes?.join(','),
      },
      url: `/bundle/price`,
    });

    return data;
  } catch (error) {
    logErrorResponse('Bundle Connector', error, `Error fetching price for bundle with code ${bundleCode}`);
    throw error;
  }
};

export type FetchBundleAlternativeProductsParams = {
  partCode: string;
  productCode: string;
};

const fetchBundleAlternativeProducts = async ({ partCode, productCode }: FetchBundleAlternativeProductsParams) => {
  try {
    const { data } = await apiClient<BundleAlternativeProducts>({
      params: {
        fields: 'FULL',
        partCode,
        productCode,
      },
      url: `/bundle/alternatives`,
    });

    return data;
  } catch (error) {
    logErrorResponse(
      'Bundle Connector',
      error,
      `Error fetching alternative products for bundle with product code ${productCode} and part code ${partCode}`,
    );
    throw error;
  }
};

export { fetchBundleAlternativeProducts, fetchBundleByProductCode, fetchBundlePrice };
