/**
 * The Redux onOrder module containing the related actions, action types and reducer.
 */

import { apiClient } from 'helpers/ApiClient';
import config from '../../config';
import { onOrderTypes } from '../../constants/order';
import { convertToIso } from '../../utils/dateUtil';

const { platform } = config;

const getOrderMethod = (isPreOrder) =>
  isPreOrder ? onOrderTypes.PRE_ORDER : onOrderTypes.ON_ORDER;

/**
 * Action types
 */
export const GET_DELIVERY_SLOTS = `${platform}/onOrder/GET_DELIVERY_SLOTS`;
export const GET_DELIVERY_SLOTS_SUCCESS = `${platform}/onOrder/GET_DELIVERY_SLOTS_SUCCESS`;
export const GET_DELIVERY_SLOTS_FAIL = `${platform}/onOrder/GET_DELIVERY_SLOTS_FAIL`;
export const SET_DELIVERY_METHOD_OPTIONS = `${platform}/onOrder/SET_DELIVERY_METHOD_OPTIONS`;
export const SUBMIT_DELIVERY_METHOD = `${platform}/onOrder/SUBMIT_DELIVERY_METHOD`;
export const SUBMIT_DELIVERY_METHOD_SUCCESS = `${platform}/onOrder/SUBMIT_DELIVERY_METHOD_SUCCESS`;
export const SUBMIT_DELIVERY_METHOD_FAIL = `${platform}/onOrder/SUBMIT_DELIVERY_METHOD_FAIL`;
export const SUBMIT_ON_ORDER = `${platform}/onOrder/SUBMIT_ON_ORDER`;
export const SUBMIT_ON_ORDER_SUCCESS = `${platform}/onOrder/SUBMIT_ON_ORDER_SUCCESS`;
export const SUBMIT_ON_ORDER_FAIL = `${platform}/onOrder/SUBMIT_ON_ORDER_FAIL`;
export const GET_ON_ORDER = `${platform}/onOrder/onOrder`;
export const GET_ON_ORDER_SUCCESS = `${platform}/checkout/GET_ON_ORDER_SUCCESS`;
export const GET_ON_ORDER_FAIL = `${platform}/onOrder/GET_ON_ORDER_FAIL`;

export const setDeliveryMethodOptions = (options) => ({
  options,
  type: SET_DELIVERY_METHOD_OPTIONS,
});

/**
 * Submits the currently selected delivery method with the selected options.
 *
 * @param {string} orderCode - The on order code.
 * @param {string} orderHash - The validation hash.
 * @param {object|undefined} options - The extra delivery options required for the selected delivery method.
 * @param {boolean} contactMe - Flag to indicate that the customer wants to be contacted for
 * a delivery moment.
 */
export const submitDeliveryMethod = (
  orderCode,
  orderHash,
  options,
  contactMe,
  isPreOrder = false
) => {
  let params;

  if (contactMe) {
    params = {
      contactMe,
      hash: orderHash,
    };
  } else {
    const elevator =
      options.deliverySlot && options.deliverySlot.elevatorStatus === 1;
    const floor =
      elevator && options.deliverySlot.selectedFloorLevel
        ? options.deliverySlot.selectedFloorLevel
        : undefined;
    const hasStairs = options.deliverySlot && options.deliverySlot.hasStairs;

    const formattedDate = new Date(options.deliverySlot.deliveryDate);
    formattedDate.setHours(0, 0, 0, 0);

    params = {
      deliveryDate: options.deliverySlot.deliveryDate
        ? convertToIso(formattedDate)
        : null,
      elevator,
      floor,
      hash: orderHash,
      stairs: hasStairs,
      timeFrame: options.deliverySlot && options.deliverySlot.moment,
    };
  }

  return {
    promise: () =>
      apiClient(
        {
          method: "POST",
          params,
          url: `/${getOrderMethod(isPreOrder)}/${orderCode}`,
        }
      ),
    types: [
      SUBMIT_DELIVERY_METHOD,
      SUBMIT_DELIVERY_METHOD_SUCCESS,
      SUBMIT_DELIVERY_METHOD_FAIL,
    ],
  };
};

/**
 * Retrieves the deliverySlots of a given delivery method and post code
 *
 * @param {string} code - The on order code.
 * @param {string} hash - The validation hash.
 * @param {string} level - The selected elevator level
 * @return {{types: [*,*,*], promise: (function(*))}} returns the deliverySlots
 */
export const getDeliverySlots = (code, hash, level = undefined, isPreOrder = false) => ({
  promise: () =>
    apiClient(
      {
        params: {
          hash,
          level,
        },
        url: `/${getOrderMethod(isPreOrder)}/${code}/slots`,
      }
    ),
  types: [
    GET_DELIVERY_SLOTS,
    GET_DELIVERY_SLOTS_SUCCESS,
    GET_DELIVERY_SLOTS_FAIL,
  ],
});

/**
 * Retrieves the confirmed order by cart id/guid.
 *
 * @param {string} code - The on order code.
 * @param {string} hash - The validation hash.
 * @return {{types: [*,*,*], promise: (function(*))}} the submitOrder action
 */
export const getOnOrder = (code, hash, isPreOrder = false) => ({
  promise: () =>
    apiClient({
      params: {
        hash,
      },
      url: `/${getOrderMethod(isPreOrder)}/${code}`,
    }),
  types: [GET_ON_ORDER, GET_ON_ORDER_SUCCESS, GET_ON_ORDER_FAIL],
});

/**
 * Submits the order.
 *
 * @param {string} code - The on order code.
 * @param {string} hash - The validation hash.
 * @return {{types: [*,*,*], promise: (function(*))}} the submitOrder action
 */
export const submitOnOrder = (code, hash, isPreOrder = false) => ({
  promise: () =>
    apiClient(
      {
        method: "POST",
        params: {
          hash,
        },
        url: `/${getOrderMethod(isPreOrder)}/${code}/confirm`,
      }
    ),
  types: [SUBMIT_ON_ORDER, SUBMIT_ON_ORDER_SUCCESS, SUBMIT_ON_ORDER_FAIL],
});

/**
 * Checks whether there placed order is loaded.
 *
 * @param {Object} globalState - The Redux global state.
 * @returns {boolean} True if already loaded, false if not.
 */
export const isOnOrderLoaded = (globalState) =>
  !!(globalState.onOrder && globalState.onOrder.order);

/**
 * Reducer
 */
const initialState = {
  deliveryMethodOptions: null,
  deliverySlots: [],
  loading: false,
  order: null,
  selectedDeliveryMethodOptions: null,
};

/**
 * The checkout reducer.
 *
 * @param {Object} state The default or current state.
 * @param {Object} action The dispatched action.
 * @returns {Object} The updated state.
 */
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_DELIVERY_METHOD_OPTIONS: {
      const { options } = action;

      return {
        ...state,
        selectedDeliveryMethodOptions: options,
      };
    }
    case GET_DELIVERY_SLOTS_SUCCESS: {
      const { liftDeliveryConfiguration, slots } = action?.result?.data;

      return {
        ...state,
        deliveryMethodOptions: liftDeliveryConfiguration,
        deliverySlots: slots || [],
      };
    }
    case GET_ON_ORDER_SUCCESS: {
      return {
        ...state,
        order: action.result.data,
      };
    }
    case SUBMIT_ON_ORDER: {
      return {
        ...state,
        loading: true,
        submitError: true,
      };
    }
    case SUBMIT_ON_ORDER_SUCCESS: {
      return {
        ...state,
        loading: false,
        order: action.result.data,
        submitError: false,
      };
    }
    case SUBMIT_ON_ORDER_FAIL: {
      return {
        ...state,
        submitError: false,
      };
    }
    default:
      return state;
  }
}
