// @FIXME Is this file actually needed?

/**
 * Loads the .env file and sets it to global scope (for server-side render).
 */
export function load() {
  const envConfig = process.env.SERVER
    ? global.envConfig
    : typeof window !== 'undefined' && window.__envConfig;

  if (!envConfig && process.env.SERVER) {
    global.envConfig = {
      API_HOST: global.api_host || process.env.NEXT_PUBLIC_API_HOST,
      API_PORT: global.api_port || process.env.NEXT_PUBLIC_API_PORT,
      API_PROTOCOL: global.api_protocol || process.env.NEXT_PUBLIC_API_PROTOCOL,
      APPLE_LOGIN_CLIENT_ID:
        global.apple_client_id || process.env.APPLE_LOGIN_CLIENT_ID,
      APPLE_LOGIN_REDIRECT_URL:
        global.apple_redirect_url || process.env.APPLE_LOGIN_REDIRECT_URL,
      AWS_DEFAULT_REGION:
        global.aws_default_region || process.env.NEXT_PUBLIC_AWS_DEFAULT_REGION,
      CONTENTFUL_DELIVERY_API_KEY:
        global.contentful_delivery_api_key ||
        process.env.NEXT_PUBLIC_CONTENTFUL_DELIVERY_API_KEY,
      CONTENTFUL_HOST:
        global.contentful_host || process.env.NEXT_PUBLIC_CONTENTFUL_HOST,
      CONTENTFUL_PREVIEW_API_KEY:
        global.contentful_preview_api_key ||
        process.env.NEXT_PUBLIC_CONTENTFUL_PREVIEW_API_KEY,
      CONTENTFUL_PREVIEW_HOST:
        global.contentful_preview_host ||
        process.env.NEXT_PUBLIC_CONTENTFUL_PREVIEW_HOST,
      CONTENTFUL_SPACE_ID:
        global.contentful_space_id ||
        process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID,
      FACEBOOK_APP_ID: global.facebook_app_id || process.env.FACEBOOK_APP_ID,
      FLIX_DISTRIBUTOR_ID:
        global.flix_distributor_id || process.env.FLIX_DISTRIBUTOR_ID,
      GOOGLE_CLIENT_ID: global.google_client_id || process.env.GOOGLE_CLIENT_ID,
      GOOGLE_TAG_MANAGER_ID:
        global.google_tag_manager_id ||
        process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID,
      LOADBEE_API_KEY:
        global.loadbee_api_key || process.env.NEXT_PUBLIC_LOADBEE_API_KEY,
      LOG_LEVEL: global.log_level || process.env.NEXT_PUBLIC_LOG_LEVEL,
      NODE_HOST: global.node_host || process.env.NEXT_PUBLIC_NODE_HOST,
      NODE_HTTP_ORIGIN:
        global.node_http_origin || process.env.NEXT_PUBLIC_NODE_HTTP_ORIGIN,
      NODE_PORT: global.node_port || process.env.NEXT_PUBLIC_NODE_PORT,
      PAYCONIQ_API_HOST:
        global.payconiq_api_host || process.env.NEXT_PUBLIC_PAYCONIQ_API_HOST,
      PLATFORM: global.platform || process.env.NEXT_PUBLIC_PLATFORM,
      PROXY_HOST: global.proxy_host || process.env.NEXT_PUBLIC_PROXY_HOST,
      PROXY_PORT: global.proxy_port || process.env.NEXT_PUBLIC_PROXY_PORT,
      PROXY_PROTOCOL:
        global.proxy_protocol || process.env.NEXT_PUBLIC_PROXY_PROTOCOL,
      PROXY_REMOVE_HEADERS:
        global.proxy_remove_headers || process.env.PROXY_REMOVE_HEADERS,
      RECAPTCHA_SITE_KEY:
        global.recaptcha_site_key || process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY,
      RECAPTCHA_SITE_SECRET:
        global.recaptcha_site_secret ||
        process.env.NEXT_PUBLIC_RECAPTCHA_SITE_SECRET,
      SALESFORCE_TRACKING_ID:
        global.sales_force_tracking_id ||
        process.env.NEXT_PUBLIC_SALESFORCE_TRACKING_ID,
    };

    return global.envConfig;
  }
  return envConfig;
}
