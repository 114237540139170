export * from './queries';

interface ProductsKeyParams {
  maxProducts: number;
  productCodes: string[];
  pushCnCToBack?: boolean;
  showOutOfStock?: boolean;
}

interface QueryKeyParams {
  currentPage?: number;
  pageSize?: number;
  pushCnCToBack?: boolean;
  query: string;
  showOutOfStock?: boolean;
}

export const productKeys = {
  accessories: (productCode?: string) => ['accessories', productCode],
  classifications: (productCode?: string) => ['productClassifications', productCode],
  deliveryModes: (productCode: string, postalCode?: string, location?: string, country?: string) => [
    'deliveryModes',
    productCode,
    postalCode,
    location,
    country,
  ],
  detail: (productCode?: string) => ['product', productCode],
  monthlyInstalment: (price: number, numberOfMonths: number) => ['monthlyInstalment', price, numberOfMonths],
  products: ({ maxProducts, productCodes, pushCnCToBack = false, showOutOfStock = false }: ProductsKeyParams) => [
    'products',
    productCodes,
    showOutOfStock,
    pushCnCToBack,
    maxProducts,
  ],
  query: ({
    currentPage = 0,
    pageSize = 5,
    pushCnCToBack = false,
    query = '',
    showOutOfStock = false,
  }: QueryKeyParams) => ['productQuery', query, currentPage, pageSize, pushCnCToBack, showOutOfStock],
  realtimeInfo: () => ['productRealtimeInfo'],
  realtimeInfoAll: (productCodes: string[]) => [...productKeys.realtimeInfo(), productCodes],
  realtimeInfoDetail: (productCode: string) => [...productKeys.realtimeInfo(), productCode],
  recentlyViewedProducts: (productCodes: string[]) => ['recentlyViewedProducts', productCodes],
  reviews: (productCode: string, currentPage: number, sort: string, pageSize: number) => [
    'reviews',
    productCode,
    currentPage,
    sort,
    pageSize,
  ],
  similar: (productCode?: string) => ['similar', productCode],
  userCanWriteReview: (productCode?: string, anonymous?: boolean) => ['userCanWriteReview', productCode, anonymous],
  variants: (productCode?: string) => ['variants', productCode],
};
