import { CMSHeaderPage, CMSPageData } from 'types/CMS';
import config from '../../config';
import { apiClient } from '../../helpers/ApiClient';
import { logErrorResponse } from '../../utils/loggerUtil';
import { uppercaseFirstLetter } from '../../utils/textUtil';

const { platform } = config;

const catalog = `${uppercaseFirstLetter(platform?.toLowerCase())}ContentCatalog`;

interface FetchCmsPageDataParams {
  qualifier: string;
}

const fetchCmsPageData = async ({ qualifier }: FetchCmsPageDataParams) => {
  try {
    const { data } = await apiClient<CMSPageData>({
      url: `/cms/sites/${platform}/catalogs/${catalog}/versions/Online/pagescontentslotscomponents?qualifier=${qualifier}`,
    });
    return data;
  } catch (error) {
    logErrorResponse('CMS Connector', error, `Error fetching cms data for page ${qualifier}`);
    throw error;
  }
};

const fetchVimeoVideoData = async (videoUrl: string) => {
  try {
    const result = await fetch(`https://vimeo.com/api/oembed.json?url=${videoUrl}`);
    return await result.json();
  } catch (error) {
    logErrorResponse('CMS Connector', error, `Error fetching vimeo video data for video with url ${videoUrl}`);
    throw error;
  }
};

const fetchCmsHeaderLinks = async (qualifier: string) => {
  try {
    const { data } = await apiClient<CMSHeaderPage>({
      params: {
        qualifier,
      },
      url: `/cms/sites/${platform}/catalogs/${catalog}/versions/Online/header`,
    });

    return data;
  } catch (error) {
    logErrorResponse('CMS Connector', error, `Error fetching cms header links`);
    throw error;
  }
};

const fetchFooter = async (locale: string) => {
  try {
    const { data } = await apiClient({
      params: {
        fields: 'FULL',
        lang: locale,
      },
      url: `/cms/sites/${platform}/catalogs/${catalog}/versions/Online/footer`,
    });

    return data;
  } catch (error) {
    logErrorResponse('CMS Connector', error, `Error fetching footer`);
    throw error;
  }
};

export { fetchCmsHeaderLinks, fetchCmsPageData, fetchFooter, fetchVimeoVideoData };
