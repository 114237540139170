import { FetchPartProductsParams, FetchUserFavoriteConfigurationsParams } from './connector';

export * from './queries';

export const pcConfiguratorKeys = {
  configuration: (uid: string) => ['configuration', uid],
  configurations: () => ['configurations'],
  customConfiguration: () => ['customConfiguration'],
  partProducts: (params: FetchPartProductsParams) => ['partProducts', params],
  userFavoriteConfigurations: (params?: FetchUserFavoriteConfigurationsParams) => [
    'userFavoriteConfigurations',
    params,
  ],
};
