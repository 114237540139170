import { useCactConsentGroupAccepted } from 'hooks/useCactConsentGroupAccepted';
import Script from 'next/script';

const ABTastySetupScript = () => {
  const scriptUrl = process.env.NEXT_PUBLIC_ABTASTY_SCRIPT_URL || '';
  const consentAccepted = useCactConsentGroupAccepted('PERFORMANCE');

  if (!scriptUrl || !consentAccepted) {
    return null;
  }

  return <Script id="abtasty-setup-script" src={scriptUrl} strategy="afterInteractive" />;
};
export default ABTastySetupScript;
