import { apiClient } from 'helpers/ApiClient';
import { WebConfig } from 'types/WebConfig';
import { logErrorResponse } from 'utils/loggerUtil';

const fetchWebConfig = async () => {
  try {
    const { data } = await apiClient<WebConfig>({
      params: { fields: 'FULL' },
      url: '/config',
    });

    return data;
  } catch (error) {
    logErrorResponse('Configuration Connector', error, 'Error fetching webconfig');
    throw error;
  }
};

export { fetchWebConfig };
