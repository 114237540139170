/* eslint-disable sort-keys-fix/sort-keys-fix */
/**
 * Configuration object containing application and environment specific confuguration.
 */
import { CURRENT_BRAND } from 'constants/brand';
import { locales } from './constants/i18n';
import { COUNTRY_CODES } from './constants/store';
import { load } from './utils/envUtil';

/**
 * Load environment variables
 */
const envConfig = load();
const currentPlatform = CURRENT_BRAND;

const isAuthCookieSecure = process.env.NEXT_PUBLIC_AUTH_COOKIE_SECURE === 'true' ?? false;

/**
 * Config object for the website containing global configuration.
 */
const config = {
  platform: currentPlatform,
  defaultCountry: process.env.NEXT_PUBLIC_DEFAULT_COUNTRY || COUNTRY_CODES.BE,
  env: {
    host: process.env.NEXT_PUBLIC_NODE_HOST,
    port: process.env.NEXT_PUBLIC_NODE_PORT,
    httpOrigin: process.env.NEXT_PUBLIC_NODE_HTTP_ORIGIN,
  },
  api: {
    protocol: process.env.NEXT_PUBLIC_API_PROTOCOL,
    host: process.env.NEXT_PUBLIC_API_HOST,
    port: process.env.NEXT_PUBLIC_API_PORT,
  },
  image: {
    baseUrl: `${process.env.NEXT_PUBLIC_API_PROTOCOL}://${process.env.NEXT_PUBLIC_API_HOST}:${process.env.NEXT_PUBLIC_API_PORT}`,
  },
  proxy: {
    protocol: process.env.NEXT_PUBLIC_PROXY_PROTOCOL,
    host: process.env.NEXT_PUBLIC_PROXY_HOST,
    port: process.env.NEXT_PUBLIC_PROXY_PORT,
    targets: ['/ingenico', '/santander'],
    remove_headers: (envConfig && envConfig.PROXY_REMOVE_HEADERS) || [],
  },
  app: {
    title: 'Krefel App',
    logLevel: process.env.NEXT_PUBLIC_LOG_LEVEL,
  },
  oauth: {
    key: isAuthCookieSecure ? '__Secure-oauth' : 'oauth',
    secure: isAuthCookieSecure,
    client: {
      client_id: 'krefel',
      client_secret: 'kr3f3l',
    },
    app_client: {
      client_id: 'krefel_app',
      client_secret: 'kr3f3l',
    },
    expirationTime: 31536000, // 1 year
  },
  soft_login: {
    key: 'soft_login',
    expirationTime: 2419200, // 1 month
  },
  cookie: {
    path: '/',
    expirationTime: 315360000,
    secure: process.env.NODE_ENV === 'production',
  },
  cart: {
    key: 'cart',
    expirationTime: 2419200, // 1 month
  },
  order: {
    key: 'order',
    expirationTime: 86400000, // 1 day
  },
  cartExitCookie: {
    key: 'cartExit',
    accepted: true,
    expirationTime: 86400000, // 1 day
  },
  breadCrumbsCookie: {
    key: 'breadcrumbs',
    breadCrumb: {},
    expirationTime: 31536000, // 1 years
  },
  productCompareCookie: {
    key: 'product_compare',
    products: {},
    expirationTime: 86400000, // 24 h
  },
  productCompareDestinationCookie: {
    key: 'product_compare_destination',
    destination: '/',
    expirationTime: 86400000, // 24 h
  },
  cookiePolicy: {
    key: 'cookie_policy',
    accepted: true,
    expirationTime: 315360000, // 10 years
  },
  locationCookie: {
    key: 'location',
    location: {},
    expirationTime: 315360000, // 10 years
  },
  favouriteStoreCookie: {
    key: 'favouriteStores',
    stores: [],
    expirationTime: 31536000, // 1 years
  },
  wishListCookie: {
    key: 'wish_list',
    products: [],
    expirationTime: 31536000, // 1 years
  },
  stockUpdateCookie: {
    key: 'stock_update',
    products: [],
    expirationTime: 31536000, // 1 years
  },
  i18n: {
    key: process.env.NEXT_PUBLIC_I18N_KEY ?? 'NEXT_LOCALE',
    path: '/',
    ...locales[currentPlatform],
    maxAge: 31536000, // 1 years
  },
  recently_viewed_products: {
    key: 'recently_viewed_products',
    max: 15,
    expirationTime: 31536000, // 1 years
  },
  search: {
    layout: {
      key: 'layout_type',
      expirationTime: 31536000, // 1 years
    },
  },
  announcements: {
    key: 'announcements',
    expirationTime: 2630000, // 1 month
  },
  webExclusives: {
    threshold: 86400000, // ms  24 h
    oneHourThreshold: 3600000, // ms 1 h
  },
  notificationEvent: {
    key: 'notificationEvent',
    path: '/',
    maxAge: 31536000, // 1 years
  },
  googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
  googleTagManagerId: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID,
  criteo: {
    account: {
      fr: process.env.NEXT_PUBLIC_CRITEO_FR,
      nl: process.env.NEXT_PUBLIC_CRITEO_NL,
    },
  },
  facebook: {
    connectUrl: 'https://connect.facebook.net',
    sdk: 'sdk.js',
    customerChatSdk: 'sdk/xfbml.customerchat.js',
    appId: process.env.NEXT_PUBLIC_FACEBOOK_APP_ID,
    xfbml: true,
    version: 'v2.11',
    autoLogAppEvents: true,
    krefelPageId: '143555695667007',
  },
  google: {
    clientId: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID,
  },
  seo: {
    seoBaseUrl: process.env.NEXT_PUBLIC_SEO_BASE_URL || 'https://www.krefel.be',
    home: '/',
  },
  breakpoints: {
    desktop_xxl: 1440,
    desktop: 1200,
    tablet: 992,
    mobile: 768,
    small: 576,
  },
  webcollage: {
    baseUrl: '//scontent.webcollage.net/api/v2/product-content',
  },
  contentProvider: {
    delay: 5000,
  },
  autheos: {
    baseUrl: 'https://cdn.autheos.com/',
    cdnToken: 'Y17RxJNeke',
    js: '/embedcode/2.1.4/embedcode.min.js',
    css: '/embedcode/2.1.4/embedcode.min.css',
  },
  payconiq: {
    ios: {
      fallbackShopLink: 'https://itunes.apple.com/nl/app/payconiq-mobile-payments/id966172246?l=en&mt=8',
      transactionLink: 'https://payconiq.com/pay/1/',
      returnUrl: '?returnUrl=',
    },
    android: {
      fallbackShopLink: 'https://play.google.com/store/apps/details?id=com.payconiq.customers&hl=en',
      transactionLink: 'intent://https://payconiq.com/pay/1/',
    },
    script: `https://${process.env.NEXT_PUBLIC_PAYCONIQ_API_HOST}/v2/online/static/widget.js`,
    scriptData: 'bootstrap',
    statusInterval: 5000,
  },
  socialLinks: {
    facebook: 'https://www.facebook.com/krefel/',
    twitter: 'https://www.twitter.com/krefel',
    instagram: 'https://www.instagram.com/krefelbe/',
    youtube: 'https://www.youtube.com/user/krefelbe/',
  },
  kiyOh: {
    url: 'https://kiyoh.com/krfel_nv/',
    fallbackScore: 0,
    fallbackWorstScore: 1,
    fallbackBestScore: 10,
    fallbackTotalReviews: 1,
  },
  retailRocket: {
    rrPartnerId: process.env.NEXT_PUBLIC_RR_PARTNER_ID,
    apiJsId: process.env.NEXT_PUBLIC_API_JS_ID,
    apiJsSrc: process.env.NEXT_PUBLIC_API_JS_SRC,
  },
  iSite: {
    url: 'https://flv.isitetv.com/media/js/player_loader/isitetv_product_card_krefel_1.0.1.min.js',
  },
  flix: {
    distributorId: process.env.NEXT_PUBLIC_FLIX_DISTRIBUTION_ID,
  },
  salesForce: {
    trackingScript: `//${process.env.NEXT_PUBLIC_SALESFORCE_TRACKING_ID}.collect.igodigital.com/collect.js`,
    orgId: process.env.NEXT_PUBLIC_SALESFORCE_TRACKING_ID ?? '',
    apiKey: process.env.NEXT_PUBLIC_SALESFORCE_API_KEY,
    updateSteam: `https://${process.env.NEXT_PUBLIC_SALESFORCE_TRACKING_ID}.collect.igodigital.com/c2/${process.env.NEXT_PUBLIC_SALESFORCE_TRACKING_ID}/update_item_secure.json`,
  },
  aws: {
    region: process.env.NEXT_PUBLIC_AWS_DEFAULT_REGION,
    newRelicParam: 'newrelic',
    params: [],
  },
  recaptcha: {
    siteKey: process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY,
    siteSecret: process.env.NEXT_PUBLIC_RECAPTCHA_SITE_SECRET,
    v3ScriptSrc: 'https://www.google.com/recaptcha/api.js?render=',
  },
  contentFul: {
    space_id: process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID,
    delivery_api: process.env.NEXT_PUBLIC_CONTENTFUL_DELIVERY_API_KEY,
    preview_delivery_api: process.env.NEXT_PUBLIC_CONTENTFUL_PREVIEW_API_KEY,
    preview_host: process.env.NEXT_PUBLIC_CONTENTFUL_PREVIEW_HOST,
    host: process.env.NEXT_PUBLIC_CONTENTFUL_HOST,
  },
  appleLogin: {
    src: 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/',
    script: '/appleid.auth.js',
    clientId: (envConfig && envConfig.APPLE_LOGIN_CLIENT_ID) || 'krefel.be',
    redirectUrl: (envConfig && envConfig.APPLE_LOGIN_REDIRECT_URL) || 'https://www.krefel.be/',
  },
  swogo: {
    baseUrl: 'https://api.swogo.net/',
    clientId: {
      nl: process.env.NEXT_PUBLIC_SWOGO_ID_NL ?? '',
      fr: process.env.NEXT_PUBLIC_SWOGO_ID_FR ?? '',
    },
  },
  interactionStudio: {
    baseUrl: 'https://krefelnv.germany-2.evergage.com/api2/event/',
  },
  postLu: {
    packupUrl: 'https://www.mypost.lu/packup-web',
  },
};

export default config;
