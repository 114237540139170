import { FetchWishlistRealtimeInfoParams } from './connector';

export const wishlistKeys = {
  anonWishlistProducts: (anonymousWishlistProducts?: string[]) => ['anonWishlistProducts', anonymousWishlistProducts],
  wishlistDetail: (id?: string, productCode?: string) => ['wishlistDetail', id, productCode],
  wishlistProducts: (id?: string, currentPage?: number) => ['wishlistProducts', id, currentPage],
  wishlistSharingLinks: (id?: string) => ['wishlistSharingOptions', id],
  wishlists: () => ['wishlists'],
  wishlistsRealtimeInfo: () => ['wishlistsRealtimeInfo'],
  wishlistsRealtimeInfoCodes: (params?: FetchWishlistRealtimeInfoParams) => [
    ...wishlistKeys.wishlistsRealtimeInfo(),
    params,
  ],
};
