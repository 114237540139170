import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { configurationKeys } from '.';
import { fetchWebConfig } from './connector';

const useWebConfig = (enabled = true) =>
  useQuery({
    enabled: !!enabled,
    placeholderData: keepPreviousData,
    queryFn: () => fetchWebConfig(),
    queryKey: configurationKeys.webConfig(),
  });

export { useWebConfig };
