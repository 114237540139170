import GlobalScripts from 'components/Scripts/GlobalScripts';
import config from 'config';
import font from 'config/nextFont/fontExport';
import { ADVANCED_PAGEVIEW_PAGES } from 'constants/analytics';
import { APP_CONTENT_ID } from 'constants/app';
import { useTranslations } from 'features/i18n/queries';
import { getOAuthCookie, setOAuthCookie } from 'helpers/OAuth2Helper';
import SwogoHelper from 'helpers/SwogoHelpers';
import useAppEvents from 'hooks/useAppEvents';
import useAuthResolver from 'hooks/useAuthResolver';
import useCart from 'hooks/useCart';
import usePrevious from 'hooks/usePrevious';
import useRouter from 'hooks/useRouter';
import { useSelector } from 'hooks/useSelector';
import useTrackingEvents from 'hooks/useTrackingEvents';
import { PAYMENT_PROVIDER_PAGES, pathnames } from 'i18n/pathnames';
import { signOut, useSession } from 'next-auth/react';
import dynamic from 'next/dynamic';
import { useEffect } from 'react';
import { Cookies, CookiesProvider } from 'react-cookie';
import { IntlProvider } from 'react-intl';
import { useDispatch, useStore as useReduxStore } from 'react-redux';
import { useAddToCartStore } from 'store/addToCart';
import { useAuthActions } from 'store/auth';
import { useNotificationActions } from 'store/notification';
import { useLoading, useUIActions } from 'store/ui';
import { StyleSheetManager, ThemeProvider } from 'styled-components';
import { GlobalStyle, defaultTheme } from 'theme';
import { Notification } from 'types/Notification';
import { setCriteoAccountNumber } from 'utils/analyticsProductDataUtil';
import { afterLoginReduxActions, afterLogoutActions, afterLogoutReduxActions } from 'utils/authUtil';
import { log } from 'utils/loggerUtil';
import { shouldForwardProp } from 'utils/styledUtil';
import { detectAuth, detectCart, detectEmbedded } from 'utils/windowUtil';

const NotificationEvent = dynamic(() => import('../NotificationEvent/NotificationEvent'));
const WishlistFlyOver = dynamic(() => import('../Wishlist/WishlistFlyOver/WishlistFlyOver'));
const CustomerFeedbackModal = dynamic(() => import('../Layout/CustomerFeedbackModal/CustomerFeedbackModal'));
const SigningModal = dynamic(() => import('../StoreFinder/SigningModal/SigningModal'));
const NotificationsWrapper = dynamic(() => import('../NotificationsWrapper/NotificationsWrapper'));
const AuthFlyOver = dynamic(() => import('../Login/AuthFlyOver/AuthFlyOver'));
const LanguageModal = dynamic(() => import('../Language/LanguageModal/LanguageModal'));
const ProductCompareMenu = dynamic(() => import('../Compare/ProductCompareMenu/ProductCompareMenu'));
const Loader = dynamic(() => import('../Layout/Loader/Loader'));

const isBrowser = typeof window !== 'undefined';

interface AppComponentProps {
  children: React.ReactNode;
}

const AppComponent = ({ children }: AppComponentProps) => {
  const dispatch = useDispatch();
  const headerAuthToken = detectAuth();
  const headerCartCookie = detectCart();
  const isWebView = detectEmbedded();
  const router = useRouter();
  const store = useReduxStore();
  const cookies = new Cookies();
  const { data: translationKeys } = useTranslations();
  const { addWindowNotifyCallback } = useAppEvents();
  const { data: session, status: nextAuthStatus } = useSession();
  const { authResolved, user } = useAuthResolver();
  const { onPageView } = useTrackingEvents();
  const { initCart } = useCart();

  const showCompareMenu = useSelector((state) => state.compare.showCompareMenu);

  const { queueNotification } = useNotificationActions();

  const globalLoading = useLoading();

  const { initAuth, logout } = useAuthActions();
  const { setLocale } = useUIActions();
  const { actions: addToCartActions, contractKey } = useAddToCartStore();

  const { asPath, isReady, locale, pathname } = router;

  const isPaymentProviderPage = PAYMENT_PROVIDER_PAGES.includes(pathname ?? '');
  const isComparePage = pathname === pathnames.COMPARE;
  const prevUser = usePrevious(user);

  useEffect(() => {
    if (authResolved && isReady && !isPaymentProviderPage) {
      initCart();
    }

    if (contractKey) {
      addToCartActions.reset();
    }
  }, [locale, authResolved, isReady, pathname]);

  useEffect(() => {
    if (locale) {
      setLocale(locale);
    }
  }, [locale]);

  const initApp = () => {
    if (!isWebView) {
      const oauthHeader = JSON.parse(headerAuthToken);
      if (headerAuthToken) {
        cookies.set(config.oauth.key, oauthHeader, {
          ...config.cookie,
          maxAge: config.oauth.expirationTime,
          secure: config.oauth.secure,
        });
      }

      if (headerCartCookie) {
        cookies.set(config.cart.key, headerCartCookie, {
          ...config.cookie,
          maxAge: config.cart.expirationTime,
        });
      }
    }

    try {
      initAuth();
    } catch (err) {
      log('initApp - components/App/AppComponent', `Failed initial login`, err);
    }

    setCriteoAccountNumber(locale);
  };

  useEffect(() => {
    initApp();
  }, [locale]);

  useEffect(() => {
    const OAuthCookie = getOAuthCookie();
    if (session?.hybrisToken && session?.hybrisToken?.access_token !== OAuthCookie?.access_token) {
      setOAuthCookie(session.hybrisToken);
      initAuth();
    }

    if (!!session?.error && !!session?.provider) {
      log('AppComponent', 'Error occured in Next Auth Session', session.error.message);
      const errorNotification: Notification = {
        message: translationKeys?.['login_session_error'] ?? '',
        titleId: translationKeys?.['notification_error_title'] ?? '',
        type: 'error',
        uuid: 'loginSessionError',
      };
      queueNotification(errorNotification);
      logout();
      if (nextAuthStatus === 'authenticated') {
        signOut({ redirect: false });
      }
    }
  }, [session]);

  useEffect(() => {
    // Basic pageView events that require no additional data.
    // advanced pageView events are to be fired in the Containers.
    if (!ADVANCED_PAGEVIEW_PAGES.some((page) => page === pathname)) {
      onPageView({ locale, path: asPath, pathname, user });
    }
  }, [asPath, locale]);

  useEffect(() => {
    if (isWebView) addWindowNotifyCallback();
  }, [isWebView]);

  // Temporary bridge between Zustand auth and Redux actions
  useEffect(() => {
    if (!user && prevUser) {
      log('AppComponent', 'Logout detected, clearing cart');
      afterLogoutActions();
      afterLogoutReduxActions(dispatch, router);
    }
    if (user && !prevUser) {
      log('AppComponent', 'Login detected, auth redux side effects');
      afterLoginReduxActions();
    }
  }, [user]);

  if (isBrowser) window.swogoHelper = new SwogoHelper(store, cookies);

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp} enableVendorPrefixes>
      <ThemeProvider theme={defaultTheme}>
        <CookiesProvider cookies={isBrowser ? undefined : cookies}>
          <IntlProvider locale={locale} messages={translationKeys} onError={() => null}>
            <GlobalStyle />
            <GlobalScripts />

            <div
              className={`outer-container screen-max-w ${font.primary.variable} ${font.secondary?.variable ?? ''}`}
              id={APP_CONTENT_ID}
            >
              {globalLoading && <Loader show={globalLoading} />}
              {children}
              <NotificationEvent />
            </div>

            {!isWebView && !isPaymentProviderPage && (
              <>
                <NotificationsWrapper />
                <LanguageModal />
              </>
            )}

            {showCompareMenu && !isComparePage && !isWebView && <ProductCompareMenu />}
            <AuthFlyOver />
            <SigningModal />
            <WishlistFlyOver />
            <CustomerFeedbackModal />
          </IntlProvider>
        </CookiesProvider>
      </ThemeProvider>
    </StyleSheetManager>
  );
};

export default AppComponent;
