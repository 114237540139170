import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query';
import useAuthResolver from 'hooks/useAuthResolver';
import useRecentProductsFunctions from 'hooks/useRecentProductsFunctions';
import useRouter from 'hooks/useRouter';
import { IProductSelector } from 'types/ContentfulTypes';
import { Product, ProductReference } from 'types/Product';
import { productKeys } from '.';
import { PRODUCT_STOCK_MESSAGE, REFERENCE_TYPES } from '../../constants/product';
import { pathnames } from '../../i18n/pathnames';
import { getProductCodeFromQuery } from '../../utils/productUtil';
import {
  fetchClassifications,
  fetchMonthlyInstalment,
  fetchProduct,
  fetchProductDeliveryModes,
  fetchProductReferences,
  fetchProductVariants,
  fetchProducts,
  fetchProductsByQuery,
  fetchRealtimeInfo,
  fetchRecentlyViewedProducts,
  fetchReviews,
  fetchUserCanWriteReview,
} from './connector';

interface UseProductParams {
  code?: string;
  enabled?: boolean;
}

const useProduct = ({ code, enabled: enabledProp = true }: UseProductParams = {}) => {
  const { pathname, query } = useRouter();
  const isPdp = pathname === pathnames.PRODUCT;
  const productCodeParam = isPdp ? getProductCodeFromQuery(query) : '';
  const productCode = code ?? productCodeParam;

  return useQuery({
    enabled: enabledProp && !!productCode,
    queryFn: () => fetchProduct(productCode),
    queryKey: productKeys.detail(productCode),
  });
};

interface UseProductsParams {
  codes: { code: string }[];
  maxProducts?: number;
  pushCnCToBack?: boolean;
  showOutOfStock?: boolean;
}

const useProducts = ({ codes, maxProducts = 50, pushCnCToBack, showOutOfStock }: UseProductsParams) => {
  const productCodes = codes?.map((product) => product.code);
  return useQuery({
    enabled: !!productCodes?.length,
    queryFn: () =>
      fetchProducts({
        filterOnStock: !showOutOfStock,
        pageSize: maxProducts,
        productCodes,
        pushCnCToBack,
      }),
    queryKey: productKeys.products({ maxProducts, productCodes, pushCnCToBack, showOutOfStock }),
  });
};

interface UseProductSelectorParams {
  maxProducts?: number;
  selector?: IProductSelector;
}

const useProductSelector = ({ maxProducts, selector }: UseProductSelectorParams) => {
  const { products, pushCnCToBack, showOutOfStockProducts } = selector?.fields || {};
  const codes = products?.activeItems ?? [];
  return useProducts({ codes, maxProducts, pushCnCToBack, showOutOfStock: showOutOfStockProducts });
};

const useRealtimeInfo = (codes: string[], enabled = true) => {
  const queryClient = useQueryClient();
  return useQuery({
    enabled: enabled && !!codes?.length,
    queryFn: async () => {
      const realtimeInfo = await fetchRealtimeInfo(codes);
      if (!realtimeInfo) return;
      const products = realtimeInfo.products;
      products.forEach((product) => {
        if (product.code)
          queryClient.setQueryData(productKeys.realtimeInfoDetail(product.code), { products: [product] });
      });
      return realtimeInfo;
    },
    queryKey: productKeys.realtimeInfoAll(codes),
  });
};

const useRealtimeInfoDetail = (productCode: string) =>
  useQuery({
    enabled: false,
    queryFn: () => fetchRealtimeInfo([productCode]),
    queryKey: productKeys.realtimeInfoDetail(productCode),
  });

interface UseReviewsProps {
  currentPage?: number;
  pageSize?: number;
  productCode: string;
  sort?: string;
}

const useReviews = ({ currentPage = 0, pageSize = 4, productCode, sort = 'CREATIONTIME_DESC' }: UseReviewsProps) =>
  useQuery({
    enabled: !!productCode,
    placeholderData: keepPreviousData,
    queryFn: () => fetchReviews({ currentPage, pageSize, productCode, sort }),
    queryKey: productKeys.reviews(productCode, currentPage, sort, pageSize),
  });

const useClassifications = ({ code, enabled = false }: { code?: string; enabled?: boolean }) => {
  const { query } = useRouter();
  const productCode = code || getProductCodeFromQuery(query);

  return useQuery({
    enabled,
    queryFn: () => fetchClassifications(productCode),
    queryKey: productKeys.classifications(productCode),
  });
};

const useProductVariants = () => {
  const { query } = useRouter();
  const productCode = getProductCodeFromQuery(query);

  return useQuery({
    enabled: !!productCode,
    queryFn: () => fetchProductVariants(productCode),
    queryKey: productKeys.variants(productCode),
  });
};

const useUserCanWriteReview = () => {
  const { query } = useRouter();
  const productCode = getProductCodeFromQuery(query);
  const { anonymous } = useAuthResolver();

  return useQuery({
    enabled: !!productCode && !anonymous,
    queryFn: () => fetchUserCanWriteReview(productCode),
    queryKey: productKeys.userCanWriteReview(productCode, anonymous),
  });
};

const useProductAccessories = (enabled = true) => {
  const { pathname, query } = useRouter();
  const productCode = getProductCodeFromQuery(query);

  const allowedPathnames: string[] = [pathnames.PRODUCT, pathnames.CATEGORY];

  const queryEnabled = allowedPathnames.includes(pathname) && !!productCode && enabled;

  return useQuery({
    enabled: queryEnabled,
    queryFn: () => fetchProductReferences({ productCode, referenceType: REFERENCE_TYPES.ACCESSORIES }),
    queryKey: productKeys.accessories(productCode),
    select: (data) =>
      data?.references.reduce((prev: Product[], reference: ProductReference) => {
        if (
          reference?.referenceType === REFERENCE_TYPES.ACCESSORIES &&
          reference?.target?.atp?.stockMessage === PRODUCT_STOCK_MESSAGE.STOCK
        ) {
          return [...prev, reference.target];
        }

        return prev;
      }, []),
  });
};

const useProductAlternatives = (enabled = true) => {
  const { query } = useRouter();
  const productCode = getProductCodeFromQuery(query);

  const queryEnabled = !!productCode && enabled;

  return useQuery({
    enabled: queryEnabled,
    queryFn: () => fetchProductReferences({ productCode, referenceType: REFERENCE_TYPES.SIMILAR }),
    queryKey: productKeys.similar(productCode),
    select: (data) =>
      data?.references
        .filter((reference) => reference?.referenceType === REFERENCE_TYPES.SIMILAR)
        .map((reference) => reference?.target),
  });
};

const useProductMonthlyInstalment = (price: number, numberOfMonths: number, enabled = true) =>
  useQuery({
    enabled: enabled && !!price,
    placeholderData: keepPreviousData,
    queryFn: () => fetchMonthlyInstalment(price, numberOfMonths),
    queryKey: productKeys.monthlyInstalment(price, numberOfMonths),
  });

const useRecentlyViewedProducts = (excludedProductCodes: string[]) => {
  const { recentlyViewedProductsCookie } = useRecentProductsFunctions();

  const productCodes = recentlyViewedProductsCookie?.filter((code) => !excludedProductCodes?.includes(code));

  return useQuery({
    enabled: !!productCodes?.length,
    queryFn: () => fetchRecentlyViewedProducts(productCodes),
    queryKey: productKeys.recentlyViewedProducts(productCodes),
  });
};

interface UseProductQueryParams {
  currentPage?: number;
  pageSize?: number;
  pushCnCToBack?: boolean;
  query: string;
  showOutOfStock?: boolean;
}

const useProductQuery = ({
  currentPage = 0,
  pageSize = 5,
  pushCnCToBack,
  query,
  showOutOfStock,
}: UseProductQueryParams) =>
  useQuery({
    enabled: !!query,
    placeholderData: keepPreviousData,
    queryFn: () =>
      fetchProductsByQuery({ currentPage, filterOnStock: !showOutOfStock, pageSize, pushCnCToBack, query }),
    queryKey: productKeys.query({ currentPage, pageSize, pushCnCToBack, query, showOutOfStock }),
  });

interface UseProductDeliveryModes {
  country?: string;
  location?: string;
  postalCode?: string;
  productCode: string;
}

const useProductDeliveryModes = ({ country, location, postalCode, productCode }: UseProductDeliveryModes) =>
  useQuery({
    enabled: !!productCode && !!location && !!postalCode,
    placeholderData: keepPreviousData,
    queryFn: () => fetchProductDeliveryModes({ country, location, postalCode, productCode }),
    queryKey: productKeys.deliveryModes(productCode, postalCode, location, country),
    select: (data) => data?.deliveryModes,
  });

export {
  useClassifications,
  useProduct,
  useProductAccessories,
  useProductAlternatives,
  useProductDeliveryModes,
  useProductMonthlyInstalment,
  useProductQuery,
  useProductSelector,
  useProductVariants,
  useProducts,
  useRealtimeInfo,
  useRealtimeInfoDetail,
  useRecentlyViewedProducts,
  useReviews,
  useUserCanWriteReview,
};
