export const createSolrQuery = (query: string, filterOnStock = false, pushCnCToBack = false) => {
  if (!query) return null;

  const prefixDelimiter = query.startsWith(':') ? '' : ':';
  const suffixDelimiter = query.endsWith(':') ? '' : ':';
  const queryWithFilters = `${pushCnCToBack ? `stockMessageScore${prefixDelimiter}` : ''}${query}${
    filterOnStock ? `${suffixDelimiter}stockMessage:atp_message_stock` : ''
  }`;

  return queryWithFilters;
};
