import { TranslationKeys } from 'types/I18n';
import config from '../../config';
import { DEFAULT_LOCALE } from '../../constants/i18n';
import { apiClient } from '../../helpers/ApiClient';
import { logErrorResponse } from '../../utils/loggerUtil';

const { platform } = config;

export const fetchTranslations = async (groups: string[], locale?: string) => {
  try {
    if (!(locale && groups) || locale === DEFAULT_LOCALE) throw new Error('Invalid locale or groups');
    const { data } = await apiClient<TranslationKeys>({
      params: {
        groups,
        lang: locale,
      },
      url: `translations/${platform}/by-group`,
      useBaseUrl: true,
    });

    return data;
  } catch (error) {
    logErrorResponse('fetchTranslations', error, 'Error fetching translations');
    throw error;
  }
};
