import { FetchBundleAlternativeProductsParams, FetchBundlePriceParams } from './connector';

export * from './queries';

export const bundleKeys = {
  bundleByProductCode: (productCode: string) => ['bundleByProductCode', productCode],
  bundlePartProductAlternatives: ({ partCode, productCode }: FetchBundleAlternativeProductsParams) => [
    'bundlePartProductAlternatives',
    partCode,
    productCode,
  ],
  bundlePrice: ({ baseProductCode, bundleCode, selectedAccessoriesProductCodes }: FetchBundlePriceParams) => [
    'bundlePrice',
    bundleCode,
    baseProductCode,
    selectedAccessoriesProductCodes,
  ],
};
