import { FacetSearchResult } from 'types/Facet';
import { PcConfiguration, PcConfigurationsResponse } from 'types/PcConfiguration';
import { apiClient } from '../../helpers/ApiClient';
import { logErrorResponse } from '../../utils/loggerUtil';

const fetchConfiguration = async (configurationUid: string) => {
  try {
    const { data } = await apiClient<PcConfiguration>({
      url: `/configurator/${configurationUid}`,
    });

    return data;
  } catch (error) {
    logErrorResponse('PC Configuration Connector', error, `Error fetching configuration with id ${configurationUid}`);
    throw error;
  }
};

const fetchConfigurations = async () => {
  try {
    const { data } = await apiClient<PcConfigurationsResponse>({
      params: { fields: 'FULL' },
      url: `/configurator/all`,
    });

    return data;
  } catch (error) {
    logErrorResponse('PC Configuration Connector', error, 'Error fetching configurations');
    throw error;
  }
};

const fetchCustomConfiguration = async () => {
  try {
    const { data } = await apiClient<PcConfiguration>({
      params: { fields: 'FULL' },
      url: `/configurator/custom`,
    });

    return data;
  } catch (error) {
    logErrorResponse('PC Configuration Connector', error, 'Error fetching custom configurations');
    throw error;
  }
};

export type FetchPartProductsParams = {
  configurationCode?: string;
  currentPage?: number;
  filterQuery?: string;
  groups?: string[];
  pageSize?: number;
  partCode: string;
  sort?: string;
};

const fetchPartProducts = async ({
  configurationCode,
  currentPage,
  filterQuery,
  groups,
  pageSize,
  partCode,
  sort,
}: FetchPartProductsParams) => {
  const params = {
    currentPage,
    fields: 'FULL',
    pageSize,
    pcConfiguration: configurationCode,
    pcConfigurationPart: partCode,
    query: filterQuery,
    selectedGroups: groups,
    sort,
  };

  try {
    const { data } = await apiClient<FacetSearchResult>({
      params,
      url: `/configurator/parts/search`,
    });

    return data;
  } catch (error) {
    logErrorResponse('PC Configuration Connector', error, 'Error fetching part products');
    throw error;
  }
};

export type FetchUserFavoriteConfigurationsParams = {
  currentPage?: number;
  pageSize?: number;
};

const fetchUserFavoriteConfigurations = async ({ currentPage, pageSize }: FetchUserFavoriteConfigurationsParams) => {
  try {
    const { data } = await apiClient<PcConfigurationsResponse>({
      params: {
        currentPage,
        fields: 'FULL',
        pageSize,
      },
      url: `/users/current/pcconfigurations`,
    });

    return data;
  } catch (error) {
    logErrorResponse('PC Configuration Connector', error, 'Error fetching favorite configurations');
    throw error;
  }
};

export type UpdateConfigurationServiceParams = {
  configurationUid: string;
  isServiceSelected: boolean;
  serviceCode: string;
};

const updateConfigurationService = async ({
  configurationUid,
  isServiceSelected,
  serviceCode,
}: UpdateConfigurationServiceParams) => {
  try {
    const { data } = await apiClient<PcConfiguration>({
      method: 'post',
      params: {
        configurationCode: configurationUid,
        selected: isServiceSelected,
        serviceCode,
      },
      url: `/configurator/service/${serviceCode}/update`,
    });

    return data;
  } catch (error) {
    logErrorResponse(
      'PC Configuration Connector',
      error,
      `Error updating  service for configuration with id ${configurationUid}`,
    );
    throw error;
  }
};

export type SubmitExtraWishesParams = {
  configurationUid: string;
  wishes: string;
};

const submitExtraWishes = async ({ configurationUid, wishes }: SubmitExtraWishesParams) => {
  try {
    const { data } = await apiClient<PcConfiguration>({
      method: 'post',
      params: {
        configurationCode: configurationUid,
        fields: 'FULL',
        remarks: wishes,
      },
      url: `/configurator/update`,
    });

    return data;
  } catch (error) {
    logErrorResponse('PC Configuration Connector', error, 'Error submitting extra wishes');
    throw error;
  }
};

export type ChangeConfigurationPartParams = {
  configurationUid: string;
  partCode: string;
  productCode?: string;
};

const changeConfigurationPart = async ({ configurationUid, partCode, productCode }: ChangeConfigurationPartParams) => {
  try {
    const { data } = await apiClient<PcConfiguration>({
      method: 'post',
      params: {
        configurationCode: configurationUid,
        fields: 'FULL',
        partCode,
        productCode,
      },
      url: '/configurator/update',
    });

    return data;
  } catch (error) {
    logErrorResponse(
      'PC Configuration Connector',
      error,
      `Error changing  part from configuration with id ${configurationUid}`,
    );
    throw error;
  }
};

const createConfiguration = async ({
  configurationCode,
  isCustomConfig,
}: {
  configurationCode: string;
  isCustomConfig: boolean;
}) => {
  try {
    const { data } = await apiClient<PcConfiguration>({
      method: 'post',
      url: `/configurator/create?configurationCode=${configurationCode}&custom=${isCustomConfig}&fields=FULL`,
    });

    return data;
  } catch (error) {
    logErrorResponse(
      'PC Configuration Connector',
      error,
      `Error creating configuration with code ${configurationCode}`,
    );
    throw error;
  }
};

const saveFavoriteConfiguration = async (configurationUid: string) => {
  try {
    const { data } = await apiClient<void>({
      method: 'post',
      params: {
        configurationCode: configurationUid,
      },
      url: `/configurator/favorite`,
    });

    return data;
  } catch (error) {
    logErrorResponse('PC Configuration Connector', error, `Error saving configuration with id ${configurationUid}`);
    throw error;
  }
};

const deleteFavoriteConfiguration = async (configurationUid: string) => {
  try {
    const { data } = await apiClient<void>({
      method: 'delete',
      params: {
        configurationCode: configurationUid,
      },
      url: `/configurator/favorite`,
    });

    return data;
  } catch (error) {
    logErrorResponse(
      'PC Configuration Connector',
      error,
      `Error deleting favorite configuration with id ${configurationUid}`,
    );
    throw error;
  }
};

const resetConfiguration = async (configurationUid: string) => {
  try {
    const { data } = await apiClient<PcConfiguration>({
      method: 'post',
      params: {
        configurationCode: configurationUid,
      },
      url: `/configurator/reset`,
    });

    return data;
  } catch (error) {
    logErrorResponse('PC Configuration Connector', error, `Error resetting configuration with id ${configurationUid}`);
    throw error;
  }
};

export {
  changeConfigurationPart,
  createConfiguration,
  deleteFavoriteConfiguration,
  fetchConfiguration,
  fetchConfigurations,
  fetchCustomConfiguration,
  fetchPartProducts,
  fetchUserFavoriteConfigurations,
  resetConfiguration,
  saveFavoriteConfiguration,
  submitExtraWishes,
  updateConfigurationService,
};
