import { useQuery } from '@tanstack/react-query';
import { useRouterParam } from 'hooks/useRouterParam';
import { pcConfiguratorKeys } from '.';
import { SORT_TYPES } from '../../constants/pcConfigurator';
import {
  fetchConfiguration,
  fetchConfigurations,
  fetchCustomConfiguration,
  fetchPartProducts,
  FetchPartProductsParams,
  fetchUserFavoriteConfigurations,
} from './connector';

const useConfiguration = (configurationUid: string, enabled = true) =>
  useQuery({
    enabled: enabled && !!configurationUid,
    queryFn: () => fetchConfiguration(configurationUid),
    queryKey: pcConfiguratorKeys.configuration(configurationUid),
  });

const useConfigurations = () =>
  useQuery({
    queryFn: () => fetchConfigurations(),
    queryKey: pcConfiguratorKeys.configurations(),
  });

const useCustomConfigurations = () =>
  useQuery({
    queryFn: () => fetchCustomConfiguration(),
    queryKey: pcConfiguratorKeys.customConfiguration(),
  });

const usePartProducts = ({
  configurationCode,
  currentPage = 0,
  filterQuery,
  groups,
  pageSize = 24,
  partCode,
  sort = SORT_TYPES.RELEVANCE,
}: FetchPartProductsParams) => {
  const configurationCodeParam = useRouterParam('id');
  const partCodeParam = useRouterParam('part');

  const enrichedParams = {
    configurationCode: configurationCode ?? configurationCodeParam,
    currentPage,
    filterQuery,
    groups,
    pageSize,
    partCode: partCode ?? partCodeParam,
    sort,
  };

  return useQuery({
    queryFn: () => fetchPartProducts(enrichedParams),
    queryKey: pcConfiguratorKeys.partProducts(enrichedParams),
  });
};

const useUserFavoriteConfigurations = ({ currentPage = 0, pageSize = 3 }) =>
  useQuery({
    queryFn: () => fetchUserFavoriteConfigurations({ currentPage, pageSize }),
    queryKey: pcConfiguratorKeys.userFavoriteConfigurations({ currentPage, pageSize }),
  });

export { useConfiguration, useConfigurations, useCustomConfigurations, usePartProducts, useUserFavoriteConfigurations };
