import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'hooks/useRouter';
import getGroupRouteMapping from 'i18n/groupRouteMapping';
import { i18nKeys } from '.';
import { fetchTranslations } from './connector';

const useTranslations = () => {
  const { locale, pathname } = useRouter();

  const groups = getGroupRouteMapping(pathname ?? '/', locale);

  return useQuery({
    queryFn: () => fetchTranslations(groups, locale),
    queryKey: i18nKeys.translations(groups),
  });
};

export { useTranslations };
