import { API_PARAM_FIELDS, ApiParamFields } from 'constants/api';
import {
  Category,
  CategoryInfoResponse,
  CategoryPromotedBrandsResponse,
  CategorySiloResponse,
  CategorySimpleWithImage,
} from 'types/Category';
import { FacetSearchResult } from 'types/Facet';
import { CategoryInfoLinksResponse } from 'types/Links';
import { apiClient } from '../../helpers/ApiClient';
import { logErrorResponse } from '../../utils/loggerUtil';
import { checkPageSize } from '../../utils/pageUtil';

const searchCategory = async (
  categoryCode: string,
  currentPage?: string | number,
  pageSize?: string | number,
  query?: string | string[],
  sort?: string | string[],
  withContentTiles?: boolean,
  fields = 'FULL',
) => {
  const pageResults = checkPageSize(Number(pageSize), withContentTiles);

  const params = {
    currentPage: currentPage || '0',
    fields,
    pageSize: pageResults,
    query,
    sort,
  };

  try {
    const { data } = await apiClient<FacetSearchResult>({
      params,
      url: `/categories/${categoryCode}/products`,
    });

    return data;
  } catch (error) {
    logErrorResponse('Category Connector', error, `Error searching category with code ${categoryCode}`);
    throw error;
  }
};

const getCategory = async (categoryCode: string, fields: ApiParamFields = 'FULL') => {
  try {
    const { data } = await apiClient<Category>({
      params: {
        fields,
        sorted: true,
      },
      url: `/categories/${categoryCode}`,
    });

    return data;
  } catch (error) {
    logErrorResponse('Category Connector', error, `Error getting category with code ${categoryCode}`);
    throw error;
  }
};

const getCategorySubCategories = async (categoryCode: string) => {
  try {
    const { data } = await apiClient<CategorySimpleWithImage>({
      params: {
        fields: API_PARAM_FIELDS.L1,
        sorted: true,
        topLevel: true,
      },
      url: `/categories/${categoryCode}`,
    });

    return data;
  } catch (error) {
    logErrorResponse('Category Connector', error, `Error getting category with code ${categoryCode}`);
    throw error;
  }
};

const getCategoryInfoUsingPath = async (categoryPath: string) => {
  try {
    const { data } = await apiClient<CategoryInfoResponse>({
      params: {
        categoryPath,
        fields: 'FULL',
      },
      url: `/categories/code`,
    });

    return data;
  } catch (error) {
    logErrorResponse('Category Connector', error, `Error getting category with paths ${categoryPath}`);
    throw error;
  }
};

const getCategorySilo = async (categoryCode: string) => {
  try {
    const { data } = await apiClient<CategorySiloResponse>({
      url: `/categories/${categoryCode}/silo`,
    });

    return data?.categories;
  } catch (error) {
    logErrorResponse('Category Connector', error, `Error getting  silo for category with code ${categoryCode}`);
    throw error;
  }
};

const fetchPopularCategories = async (pageSize = 500) => {
  try {
    const { data } = await apiClient({
      params: {
        pageSize,
      },
      url: `/categories/popular`,
    });

    return data;
  } catch (error) {
    logErrorResponse('Category Connector', error, 'Error fetching popular categories');
    throw error;
  }
};

const getCategoryInfoLinks = async (categoryCode?: string) => {
  try {
    const { data } = await apiClient<CategoryInfoLinksResponse>({
      params: {
        code: categoryCode,
        fields: 'FULL',
      },
      url: `/links/search`,
    });

    return data;
  } catch (error) {
    logErrorResponse('Category Connector', error, `Error fetching infoLinks for category with code: ${categoryCode}`);
    throw error;
  }
};

const getPromotedBrandsByCategory = async (categoryCode?: string) => {
  try {
    const { data } = await apiClient<CategoryPromotedBrandsResponse>({
      params: {
        fields: 'FULL',
      },
      url: `/categories/${categoryCode}/promotedbrands`,
    });

    return data;
  } catch (error) {
    logErrorResponse('Category Connector', error, `Error fetching promoted for category with code: ${categoryCode}`);
    throw error;
  }
};

export {
  fetchPopularCategories,
  getCategory,
  getCategoryInfoLinks,
  getCategoryInfoUsingPath,
  getCategorySilo,
  getCategorySubCategories,
  getPromotedBrandsByCategory,
  searchCategory,
};
