/**
 * The Redux product module containing the krefel service related action, action types and reducer.
 */

import config from '../../config';
import { apiClient } from 'helpers/ApiClient';

const { platform } = config;

/**
 * Action types
 */
export const GET_REPAIR_REQUEST_PRODUCT = `${platform}/krefelService/GET_REPAIR_REQUEST_PRODUCT`;
export const GET_REPAIR_REQUEST_PRODUCT_SUCCESS = `${platform}/krefelService/GET_REPAIR_REQUEST_PRODUCT_SUCCESS`;
export const GET_REPAIR_REQUEST_PRODUCT_FAIL = `${platform}/krefelService/GET_REPAIR_REQUEST_PRODUCT_FAIL`;
export const SUBMIT_REPAIR_REQUEST = `${platform}/krefelService/SUBMIT_REPAIR_REQUEST`;
export const SUBMIT_REPAIR_REQUEST_SUCCESS = `${platform}/krefelService/SUBMIT_REPAIR_REQUEST_SUCCESS`;
export const SUBMIT_REPAIR_REQUEST_FAIL = `${platform}/krefelService/SUBMIT_REPAIR_REQUEST_FAIL`;
export const GET_PRODUCT_REPAIR = `${platform}/krefelService/GET_PRODUCT_REPAIR`;
export const GET_PRODUCT_REPAIR_SUCCESS = `${platform}/krefelService/GET_PRODUCT_REPAIR_SUCCESS`;
export const GET_PRODUCT_REPAIR_FAIL = `${platform}/krefelService/GET_PRODUCT_REPAIR_FAIL`;
export const GET_PRODUCT_PART = `${platform}/krefelService/GET_PRODUCT_PART`;
export const GET_PRODUCT_PART_SUCCESS = `${platform}/krefelService/GET_PRODUCT_PART_SUCCESS`;
export const GET_PRODUCT_PART_FAIL = `${platform}/krefelService/GET_PRODUCT_PART_FAIL`;
export const GET_DELIVERY_PRODUCT = `${platform}/krefelService/GET_DELIVERY_PRODUCT`;
export const GET_DELIVERY_PRODUCT_SUCCESS = `${platform}/krefelService/GET_DELIVERY_PRODUCT_SUCCESS`;
export const GET_DELIVERY_PRODUCT_FAIL = `${platform}/krefelService/GET_DELIVERY_PRODUCT_FAIL`;
export const GET_SUPPLIER_REQUEST = `${platform}/services/repairrequest/download`;
export const GET_SUPPLIER_REQUEST_SUCCESS = `${platform}/services/repairrequest/download/GET_SUPPLIER_REQUEST_SUCCES`;
export const GET_SUPPLIER_REQUEST_FAIL = `${platform}/services/repairrequest/download/GET_SUPPLIER_REQUEST_FAIL`;
export const GET_RETURN_PRODUCT = `${platform}/krefelService/GET_RETURN_PRODUCT`;
export const GET_RETURN_PRODUCT_SUCCESS = `${platform}/krefelService/GET_RETURN_PRODUCT_SUCCESS`;
export const GET_RETURN_PRODUCT_FAIL = `${platform}/krefelService/GET_RETURN_PRODUCT_FAIL`;
export const GET_RETURN_PRODUCT_URL = `${platform}/krefelService/GET_RETURN_PRODUCT_URL`;
export const GET_RETURN_PRODUCT_URL_SUCCESS = `${platform}/krefelService/GET_RETURN_PRODUCT_URL_SUCCESS`;
export const GET_RETURN_PRODUCT_URL_FAIL = `${platform}/krefelService/GET_RETURN_PRODUCT_URL_FAIL`;
export const SUBMIT_RETURN_PRODUCT = `${platform}/krefelService/SUBMIT_RETURN_PRODUCT`;
export const SUBMIT_RETURN_PRODUCT_SUCCESS = `${platform}/krefelService/SUBMIT_RETURN_PRODUCT_SUCCESS`;
export const SUBMIT_RETURN_PRODUCT_FAIL = `${platform}/krefelService/SUBMIT_RETURN_PRODUCT_FAIL`;
export const ENABLE_MESSENGER = `${platform}/krefelService/ENABLE_MESSENGER`;
export const GET_ONLINE_DELIVERY = `${platform}/krefelService/GET_ONLINE_DELIVERY`;
export const GET_ONLINE_DELIVERY_SUCCESS = `${platform}/krefelService/GET_ONLINE_DELIVERY_SUCCESS`;
export const GET_ONLINE_DELIVERY_FAIL = `${platform}/krefelService/GET_ONLINE_DELIVERY_FAIL`;

/**
 * Actions
 */

/**
 * Function which retrieves the online delivery object for a given order code and contract key
 * @param {string} orderCode - the order code
 * @param {string} contractKey - the contract key
 * @return {{types: string[], promise: (function(*): *)}} the get online delivery action
 */
export function getOnlineDelivery(orderCode, contractKey) {
  return {
    promise: () =>
      apiClient({
        params: {
          contractKey,
          fields: 'TRACKING',
          orderCode,
        },
        url: `/services/tracking`,
      }),
    types: [
      GET_ONLINE_DELIVERY,
      GET_ONLINE_DELIVERY_SUCCESS,
      GET_ONLINE_DELIVERY_FAIL,
    ],
  };
}
/**
 * Function which returns the repair request product object for a given repairId and article number
 * @param {string} repairId - the repair id
 * @param {string} articleNumber - the article number
 * @returns {{types: [*,*,*], promise: (function(*))}} the get repair request product action
 */
export function getRepairRequestProduct(repairId, articleNumber) {
  return {
    promise: () =>
      apiClient({
        params: {
          fields: 'FULL',
          krefelCode: articleNumber,
          ticketNumber: repairId,
        },
        url: `/services/repairrequest`,
      }),
    types: [
      GET_REPAIR_REQUEST_PRODUCT,
      GET_REPAIR_REQUEST_PRODUCT_SUCCESS,
      GET_REPAIR_REQUEST_PRODUCT_FAIL,
    ],
  };
}

/**
 * Function which submits the repair request
 * @param {object} repairRequest - the repair request
 * @returns {{types: [*,*,*], promise: (function(*): (*|Request))}} the submit repair request action
 */
export function submitRepairRequest(repairRequest) {
  return {
    promise: () =>
      apiClient({
        data: repairRequest, method: "POST",
        url: `/services/repairrequest`,
      }),
    types: [
      SUBMIT_REPAIR_REQUEST,
      SUBMIT_REPAIR_REQUEST_SUCCESS,
      SUBMIT_REPAIR_REQUEST_FAIL,
    ],
  };
}

/**
 * Function which returns the repair request object for a given repairId and postal code
 * @param {string} repairId - the repair id
 * @param {string} postalCode - the postal code
 * @returns {{types: [*,*,*], promise: (function(*))}} the get product repair action
 */
export function getProductRepair(repairId, postalCode) {
  return {
    promise: () =>
      apiClient({
        params: {
          fields: 'FULL',
          postalCode,
          repairId,
        },
        url: `/services/repairs`,
      }),
    types: [
      GET_PRODUCT_REPAIR,
      GET_PRODUCT_REPAIR_SUCCESS,
      GET_PRODUCT_REPAIR_FAIL,
    ],
  };
}

/**
 * Function which returns part request object for a given repair id an postal code
 * @param {string} repairId - the repair id
 * @param {string} postalCode - the postal code
 * @returns {{types: [*,*,*], promise: (function(*))}} the get product part action
 */
export function getProductPart(repairId, postalCode) {
  return {
    promise: () =>
      apiClient({
        params: {
          fields: 'FULL',
          postalCode,
          repairId,
        },
        url: `/services/parts`
      }),
    types: [GET_PRODUCT_PART, GET_PRODUCT_PART_SUCCESS, GET_PRODUCT_PART_FAIL],
  };
}

/**
 * Function which returns the product delivery object
 * @param {string} deliveryId - the delivery id
 * @param {string} productCode - the product code
 * @returns {{types: [*,*,*], promise: (function(*))}} the get product delivery action
 */
export function getProductDelivery(deliveryId, productCode) {
  return {
    promise: () =>
      apiClient({
        params: {
          deliveryId,
          fields: 'FULL',
          productCode,
        },
        url: `/services/deliveries`,
      }),
    types: [
      GET_DELIVERY_PRODUCT,
      GET_DELIVERY_PRODUCT_SUCCESS,
      GET_DELIVERY_PRODUCT_FAIL,
    ],
  };
}

/**
 * Function which returns the return product object
 * @param {string} ticketNumber - the repair ticket number
 * @param {string} postalCode - the postal code
 * @param {string} hash - the return product hash
 * @returns {{types: [*,*,*], promise: (function(*))}} the get return product by code action
 */
export function getReturnProductByCode(ticketNumber, postalCode, hash) {
  return {
    promise: () =>
      apiClient(
        {
          params: {
            fields: 'FULL',
            hash,
          },
          url: `/repairquotation/${ticketNumber}/${postalCode}`,
        }
      ),
    types: [
      GET_RETURN_PRODUCT,
      GET_RETURN_PRODUCT_SUCCESS,
      GET_RETURN_PRODUCT_FAIL,
    ],
  };
}

/**
 * Function which returns the return product url object
 * @param {string} ticketNumber - the return product ticket number
 * @param {string} postalCode - the return product postal code
 * @returns {{types: [*,*,*], promise: (function(*))}}
 * the get return product url by ticket number action
 */
export function getReturnProductUrl(ticketNumber, postalCode) {
  return {
    promise: () =>
      apiClient(
        { url: `/repairquotation/url/${ticketNumber}/${postalCode}` }
      ),
    types: [
      GET_RETURN_PRODUCT_URL,
      GET_RETURN_PRODUCT_URL_SUCCESS,
      GET_RETURN_PRODUCT_URL_FAIL,
    ],
  };
}

/**
 * Function which posts the return product with the selected recycle option
 * @param {object} returnProduct - the return product object
 * @param {string} ticketNumber - the repair ticket number
 * @param {string} postalCode - the postalCode
 * @returns {{types: [*,*,*], promise: (function(*): (Request|*))}}
 * the submit return product with options action
 */
export function submitReturnProductWithOptions(
  returnProduct,
  ticketNumber,
  postalCode
) {
  return {
    promise: () =>
      apiClient(
        {
          data: returnProduct,
          method: "POST",
          url: `/repairquotation/${ticketNumber}/${postalCode}`,
        }
      ),
    types: [
      SUBMIT_RETURN_PRODUCT,
      SUBMIT_RETURN_PRODUCT_SUCCESS,
      SUBMIT_RETURN_PRODUCT_FAIL,
    ],
  };
}

/**
 * Function which returns the suppliers request  object for a given supplierId and article number
 * @param {string} supplierId - the suppliers id
 * @param {string} articleNumber - the article number
 * @returns {{types: [*,*,*], promise: (function(*))}} the get supplier request action
 */
export function getSupplierRequest(supplierId, articleNumber) {
  return {
    promise: () =>
      apiClient(
        {
          params: {
            fields: 'FULL',
            krefelCode: articleNumber,
            ticketNumber: supplierId,
          },
          url: `/services/repairrequest/download`,
        },
      ),
    types: [
      GET_SUPPLIER_REQUEST,
      GET_SUPPLIER_REQUEST_SUCCESS,
      GET_SUPPLIER_REQUEST_FAIL,
    ],
  };
}

/**
 * Function which enables the messenger customer chat
 * @param {boolean} enabled - the enabled flag
 * @returns {{type: *, enabled: boolean}} the enable messenger action
 */
export function enableMessenger(enabled = true) {
  return {
    enabled,
    type: ENABLE_MESSENGER,
  };
}

/**
 * Reducer
 */
const initialState = {
  deliveryProduct: null,
  isLoading: false,
  isLoadingSupplierProduct: false,
  isSearching: false,
  messengerActive: true,
  onlineOrder: null,
  productPart: null,
  repairProduct: null,
  repairRequestProduct: null,
  returnProduct: null,
  suppliersProduct: null,
};

/**
 * The giftCard reducer.
 * @since 1.0.0
 * @param {Object} state The default or current state.
 * @param {Object} action The dispatched action.
 * @returns {Object} The updated state.
 */
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_REPAIR_REQUEST_PRODUCT_SUCCESS: {
      return {
        ...state,
        repairRequestProduct: action?.result?.data,
      };
    }
    case GET_PRODUCT_REPAIR_SUCCESS: {
      return {
        ...state,
        repairProduct: action?.result?.data,
      };
    }
    case GET_PRODUCT_PART_SUCCESS: {
      return {
        ...state,
        productPart: action?.result?.data,
      };
    }
    case GET_DELIVERY_PRODUCT_SUCCESS: {
      return {
        ...state,
        deliveryProduct: action?.result?.data,
      };
    }
    case GET_SUPPLIER_REQUEST: {
      return {
        ...state,
        isLoadingSupplierProduct: true,
      };
    }
    case GET_SUPPLIER_REQUEST_SUCCESS: {
      return {
        ...state,
        isLoadingSupplierProduct: false,
        suppliersProduct: action?.result?.data,
      };
    }
    case GET_SUPPLIER_REQUEST_FAIL: {
      return {
        ...state,
        isLoadingSupplierProduct: false,
      };
    }
    case GET_RETURN_PRODUCT_URL:
    case GET_RETURN_PRODUCT: {
      return {
        ...state,
        isSearching: true,
      };
    }
    case SUBMIT_RETURN_PRODUCT_SUCCESS:
    case GET_RETURN_PRODUCT_SUCCESS: {
      return {
        ...state,
        isSearching: false,
        returnProduct: action?.result?.data,
      };
    }
    case GET_RETURN_PRODUCT_URL_SUCCESS: {
      return {
        ...state,
        isSearching: false,
      };
    }
    case GET_RETURN_PRODUCT_URL_FAIL:
    case GET_RETURN_PRODUCT_FAIL: {
      return {
        ...state,
        isSearching: false,
      };
    }
    case ENABLE_MESSENGER: {
      return {
        ...state,
        messengerActive: action?.enabled,
      };
    }
    case GET_ONLINE_DELIVERY_SUCCESS: {
      return {
        ...state,
        onlineOrder: action?.result?.data,
      };
    }
    default:
      return state;
  }
}
