export const searchKeys = {
  algoliaApiKey: () => ['algoliaApiKey'],
  isIpWhiteListed: () => ['isIpWhiteListed'],
  querySuggestions: (locale: string, amount: number) => ['querySuggestions', locale, amount],
  refinementValues: (refinements: string[]) => ['refinementValues', refinements],
  search: (
    currentPage: number,
    pageSize: number,
    query: string | string[],
    sort: string | string[],
    initialQuery: string,
    pageQualifier: string
  ) => ['searchProducts', currentPage, pageSize, query, sort, initialQuery, pageQualifier],
  searchSuggestions: (locale: string) => ['searchSuggestions', locale],
  sortValues: () => ['sortValues'],
  userIpAddress: () => ['userIpAddress'],
};
