import config from '../config';
import { log } from '../utils/loggerUtil';
import { uppercaseFirstLetter } from '../utils/textUtil';
import { apiClient } from './ApiClient';

const { platform } = config;

/**
 * Helper for retrieving CMS pages from hybris.
 */

class CMSHelper {
  constructor() {
    this.catalog = `${uppercaseFirstLetter(platform?.toLowerCase())}ContentCatalog`;
  }

  handleGetCmsPageResponse(result, qualifier) {
    const { orderedContentSlotsListData, ...props } = result;
    const slotsPerPage = orderedContentSlotsListData || [];
    const componentPromises = [];
    const page = {
      ...props,
      qualifier,
      slots: [],
    };

    if (slotsPerPage && slotsPerPage.slots) {
      slotsPerPage.slots.forEach((slotPerPage) => {
        const slot = {
          components: [],
          position: slotPerPage.position,
          slotId: slotPerPage.slotId,
          template: slotPerPage.template,
        };

        if (typeof window !== 'undefined') {
          log('CMS', `Loading slot [${slot.slotId}] for page [${page.qualifier}]`);
        }

        page.slots.push(slot);

        slotPerPage.components.forEach((slotComponent) => {
          const component = {
            componentId: slotComponent.componentId,
          };

          if (typeof window !== 'undefined') {
            log(
              'CMS',
              `Loading component [${component.componentId}] for slot [${slot.slotId}] for page [${page.qualifier}]`
            );
          }

          slot.components.push(component);

          const componentPromise = new Promise((resolveComponent) => {
            this.getCMSComponent(slotComponent.componentId)
              .then((componentResult) => {
                const successComponent = slot.components.find(
                  (filterComp) => filterComp.componentId === slotComponent.componentId
                );

                if (successComponent) {
                  const updateIndex = slot.components.indexOf(successComponent);

                  if (updateIndex >= 0) {
                    slot.components[updateIndex] = componentResult.data;
                  }
                }

                resolveComponent();
              })
              .catch(() => {
                const failedComponent = slot.components.find(
                  (filterComp) => filterComp.componentId === slotComponent.componentId
                );

                if (failedComponent) {
                  const removeIndex = slot.components.indexOf(failedComponent);

                  if (removeIndex >= 0) {
                    slot.components.splice(removeIndex, 1);
                  }
                }

                resolveComponent();

                if (typeof window !== 'undefined') {
                  log('CMS', `Component with id ${slotComponent.componentId} not found.`);
                }
              });
          });

          componentPromises.push(componentPromise);
        });
      });
    }

    return { componentPromises, page };
  }

  /**
   * Retrieve a CMS page with content slots and components
   *
   * @param {string} qualifier - The localized content page qualifier
   * @returns {Promise} Success or fail (reject).
   */
  getCMSPage(qualifier) {
    return new Promise((resolve, reject) => {
      apiClient({
        url: `/cms/sites/${platform}/catalogs/${this.catalog}/versions/Online/pagescontentslotscomponents?qualifier=${qualifier}`,
      })
        .then(async ({ data }) => {
          const { componentPromises, page } = await this.handleGetCmsPageResponse(data, qualifier);

          Promise.all(componentPromises).then(() => resolve(page));
        })
        .catch((error) => reject(error));
    });
  }

  /**
   * Retrieves the details of a CMS component from hybris by component ID.
   *
   * @param {string} componentId - The component unique ID.
   * @returns {Promise} Success or fail (reject).
   */
  async getCMSComponent(componentId) {
    return apiClient({
      url: `/cms/sites/${platform}/catalogs/${this.catalog}/versions/Online/items/${componentId}`,
    });
  }
}

export default CMSHelper;
