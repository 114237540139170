/* eslint-disable no-param-reassign */
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import https from 'https';
import QueryString from 'qs';
import { useAuthStore } from 'store/auth';
import { useUIStore } from 'store/ui';
import { OAuthToken } from 'types/Auth';
import Cookies from 'universal-cookie';
import appConfig from '../config';
import { log } from '../utils/loggerUtil';

const HEADERS = {
  AUTHORIZATION: 'Authorization',
};

const { platform } = appConfig;
const isServer = process.env.SERVER;
const isLocal = process.env.DEVELOPMENT;

const BASE_URL = `${appConfig.api.protocol}://${appConfig.api.host}:${appConfig.api.port}`;
const BASE_URL_WITH_PLATFORM = `${BASE_URL}/api/v2/${platform}`;

const AXIOS_CONFIG_BASE: AxiosRequestConfig = {
  baseURL: BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  httpsAgent: new https.Agent({ keepAlive: true }),
  paramsSerializer: (params) => QueryString.stringify(params, { arrayFormat: 'repeat' }),
  timeout: 30000,
};

const sharedRequestInterceptor = (config: AxiosRequestConfig) => {
  if (!config.headers) config.headers = {};
  if (isServer) config.headers.Origin = appConfig.env.httpOrigin ?? '';
  if (isLocal) config.headers.env = 'local';

  // Only override lang parameter on the client
  // because the lang parameter is already correct on the server
  if (typeof window !== 'undefined') {
    const locale = useUIStore.getState().locale;
    const cookies = new Cookies();
    const accessToken = cookies.get(appConfig.oauth.key)?.access_token;

    if (locale) {
      config.params = { ...config.params, lang: locale };
    }
    if (accessToken) {
      // @ts-ignore
      config.headers.common[HEADERS.AUTHORIZATION] = `Bearer ${accessToken}`;
    }
  }
  return config;
};

const sharedRequestInterceptorErrorHandler = (error: AxiosError) => {
  log('ApiClient', 'Request failed', error);
  return Promise.reject(error);
};

const apiInstance = axios.create({
  ...AXIOS_CONFIG_BASE,
  baseURL: BASE_URL_WITH_PLATFORM,
});

apiInstance.interceptors.request.use(sharedRequestInterceptor, sharedRequestInterceptorErrorHandler);

let refreshQueue: ((token: OAuthToken) => void)[] = [];

const processRefreshQueue = (token: OAuthToken) => {
  log('ApiClient.ts', 'Processing refresh queue');
  refreshQueue.forEach((prom) => prom(token));
  refreshQueue = [];
};

apiInstance.interceptors.response.use(
  async (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response) {
      if (error.response.status === 401 && !originalRequest.url?.includes('oauth/token') && !originalRequest._retry) {
        log('ApiClient.ts', 'Unauthorized request', originalRequest.url);
        log('ApiClient.ts', 'isRefreshing:', useAuthStore.getState().refreshing);
        originalRequest._retry = true;

        if (!useAuthStore.getState().refreshing) {
          try {
            const { token } = await useAuthStore.getState().actions.refreshAuth();
            originalRequest.headers[HEADERS.AUTHORIZATION] = `Bearer ${token.access_token}`;
            log('ApiClient.ts', 'new Token:', token);
            processRefreshQueue(token);
          } catch (refreshError) {
            log('ApiClient.ts', 'Something went wrong while refreshing access token', refreshError);
          }
          return apiInstance(originalRequest);
        }

        log('ApiClient.ts', 'Pushing request to refresh queue');
        const retryRequest = new Promise((resolve) => {
          refreshQueue.push((token: OAuthToken) => {
            originalRequest.headers[HEADERS.AUTHORIZATION] = `Bearer ${token.access_token}`;
            log('ApiClient.ts', 'Resolving request', originalRequest.url);
            resolve(apiInstance(originalRequest));
          });
        });
        return retryRequest;
      }
    }
    return Promise.reject(error);
  },
);

interface CustomRequestConfig extends AxiosRequestConfig {
  addAuthorizationHeader?: boolean;
  isExternalUrl?: boolean;
  useBaseUrl?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const apiClient = <T = any>({ isExternalUrl, useBaseUrl, ...request }: CustomRequestConfig) => {
  const overrides: AxiosRequestConfig = {
    ...(useBaseUrl && { baseURL: BASE_URL }),
    ...(isExternalUrl && { baseURL: '' }),
  };
  return apiInstance.request<T>({
    ...request,
    ...overrides,
  });
};

type ApiClientType = typeof apiClient;

export { apiClient, apiInstance };
export type { ApiClientType };
