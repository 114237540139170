// This file defines to mapping of each route with its corresponding translation groups

import { DEFAULT_GROUPS, GROUPS, TranslationGroup } from '../constants/i18n';
import { Pathname, delocalizedPathnames, pathnames } from './pathnames';

const groupRouteMapping: Partial<Record<Pathname, TranslationGroup[]>> = {
  [pathnames.INDEX]: [GROUPS.CART_DELIVERY_FORM, GROUPS.CHECKOUT, GROUPS.CMS],
  [pathnames.CART]: [
    GROUPS.ERRORS,
    GROUPS.CART,
    GROUPS.DELIVERY,
    GROUPS.ORDER,
    GROUPS.CHECKOUT,
    GROUPS.PRODUCT_DETAIL,
    GROUPS.CART_DELIVERY_FORM,
  ],
  [pathnames.BRAND_INDEX]: [GROUPS.BRANDS, GROUPS.DELIVERY, GROUPS.WISH_LIST],
  [pathnames.BRAND_DETAIL]: [GROUPS.BRANDS, GROUPS.DELIVERY, GROUPS.WISH_LIST],
  [pathnames.BRAND_CATEGORY]: [GROUPS.BRANDS, GROUPS.DELIVERY, GROUPS.CATEGORY],
  [pathnames.BRAND_CATEGORY_ALL]: [GROUPS.BRANDS, GROUPS.DELIVERY, GROUPS.CATEGORY],
  [pathnames.PRODUCT]: [
    GROUPS.PRODUCT_DETAIL,
    GROUPS.REVIEWS,
    GROUPS.CART,
    GROUPS.PRODUCT_INSTALMENT,
    GROUPS.DELIVERY,
    GROUPS.TONES,
    GROUPS.STORE_FINDER,
    GROUPS.TONES,
  ],
  [pathnames.CATEGORY]: [
    GROUPS.DELIVERY,
    GROUPS.HERO_HEADER,
    GROUPS.CATEGORY,
    GROUPS.PRODUCT_DETAIL,
    GROUPS.CART,
    GROUPS.CMS,
  ],
  [pathnames.CHECKOUT_CONFIRMATION]: [
    GROUPS.CART,
    GROUPS.CART_DELIVERY_FORM,
    GROUPS.DELIVERY,
    GROUPS.ERRORS,
    GROUPS.ORDER,
    GROUPS.CHECKOUT,
    GROUPS.PAYMENT_DETAILS,
    GROUPS.BASKET_OVERVIEW,
  ],
  [pathnames.CHECKOUT_SHIPPING]: [
    GROUPS.CART,
    GROUPS.CART_DELIVERY_FORM,
    GROUPS.DELIVERY,
    GROUPS.ERRORS,
    GROUPS.ORDER,
    GROUPS.CHECKOUT,
    GROUPS.ADDRESS,
    GROUPS.FORM,
  ],
  [pathnames.CHECKOUT_DELIVERY]: [
    GROUPS.CART,
    GROUPS.CART_DELIVERY_FORM,
    GROUPS.DELIVERY,
    GROUPS.ERRORS,
    GROUPS.ORDER,
    GROUPS.CHECKOUT,
    GROUPS.STORE_FINDER,
  ],
  [pathnames.CHECKOUT_LOGIN]: [GROUPS.CHECKOUT],
  [pathnames.CHECKOUT_OVERVIEW]: [
    GROUPS.CHECKOUT,
    GROUPS.CART,
    GROUPS.ERRORS,
    GROUPS.ORDER,
    GROUPS.CART_DELIVERY_FORM,
    GROUPS.BASKET_OVERVIEW,
    GROUPS.PAYMENT_DETAILS,
    GROUPS.DELIVERY,
    GROUPS.PRODUCT_DETAIL,
  ],
  [pathnames.CHECKOUT_PAYMENT]: [
    GROUPS.CART,
    GROUPS.CART_DELIVERY_FORM,
    GROUPS.DELIVERY,
    GROUPS.ERRORS,
    GROUPS.ORDER,
    GROUPS.CHECKOUT,
    GROUPS.BASKET_OVERVIEW,
    GROUPS.PRODUCT_INSTALMENT,
    GROUPS.BUDGET_CARD,
    GROUPS.GIFT_CARD,
  ],
  [pathnames.CHECKOUT_PAYMENT_REMAINING]: [
    GROUPS.CART,
    GROUPS.CART_DELIVERY_FORM,
    GROUPS.DELIVERY,
    GROUPS.ERRORS,
    GROUPS.ORDER,
    GROUPS.CHECKOUT,
    GROUPS.BASKET_OVERVIEW,
    GROUPS.PRODUCT_INSTALMENT,
    GROUPS.BUDGET_CARD,
    GROUPS.GIFT_CARD,
  ],
  [pathnames.CONTENTFUL_PREVIEW]: [
    GROUPS.CMS,
    GROUPS.DELIVERY,
    GROUPS.DELIVERY_SERVICE,
    GROUPS.ORDER,
    GROUPS.REPAIR_SERVICE,
    GROUPS.SERVICES,
    GROUPS.PRODUCT_DETAIL,
    GROUPS.CART,
    GROUPS.CATEGORY,
    GROUPS.BUDGET_CARD,
    GROUPS.CONTACT,
    GROUPS.PRIVACY,
  ],
  [pathnames.CMSPAGE]: [
    GROUPS.CMS,
    GROUPS.DELIVERY,
    GROUPS.DELIVERY_SERVICE,
    GROUPS.ORDER,
    GROUPS.REPAIR_SERVICE,
    GROUPS.SERVICES,
    GROUPS.PRODUCT_DETAIL,
    GROUPS.CART,
    GROUPS.CATEGORY,
    GROUPS.BUDGET_CARD,
    GROUPS.CONTACT,
    GROUPS.PRIVACY,
  ],
  [pathnames.DELIVERIES]: [
    GROUPS.DELIVERY_SERVICE,
    GROUPS.CART_DELIVERY_FORM,
    GROUPS.DELIVERY_SERVICE,
    GROUPS.SERVICES,
    GROUPS.ORDER,
    GROUPS.CMS,
    GROUPS.BUDGET_CARD,
    GROUPS.CONTACT,
    GROUPS.PRIVACY,
  ],
  [pathnames.DELIVERIES_ONLINE]: [
    GROUPS.DELIVERY_SERVICE,
    GROUPS.CART_DELIVERY_FORM,
    GROUPS.DELIVERY_SERVICE,
    GROUPS.SERVICES,
    GROUPS.ORDER,
    GROUPS.CMS,
    GROUPS.BUDGET_CARD,
    GROUPS.CONTACT,
    GROUPS.PRIVACY,
  ],
  [pathnames.DELIVERIES_SHOPS]: [
    GROUPS.DELIVERY_SERVICE,
    GROUPS.CART_DELIVERY_FORM,
    GROUPS.DELIVERY_SERVICE,
    GROUPS.SERVICES,
    GROUPS.ORDER,
    GROUPS.CMS,
    GROUPS.BUDGET_CARD,
    GROUPS.CONTACT,
    GROUPS.PRIVACY,
  ],
  [pathnames.ON_ORDER]: [
    GROUPS.CART,
    GROUPS.CART_DELIVERY_FORM,
    GROUPS.DELIVERY,
    GROUPS.ORDER,
    GROUPS.CHECKOUT,
    GROUPS.STORE_FINDER,
    GROUPS.ORDER,
    GROUPS.ONORDER,
  ],
  [pathnames.PRE_ORDER]: [
    GROUPS.CART,
    GROUPS.CART_DELIVERY_FORM,
    GROUPS.DELIVERY,
    GROUPS.ORDER,
    GROUPS.CHECKOUT,
    GROUPS.STORE_FINDER,
    GROUPS.ORDER,
    GROUPS.ONORDER,
  ],
  [pathnames.PASSWORD_FORGOTTEN]: [GROUPS.PASSWORD_FORGOTTEN],
  [pathnames.PASSWORD_FORGOTTEN_REQUEST]: [GROUPS.PASSWORD_FORGOTTEN],
  [pathnames.PC_CONFIGURATOR]: [GROUPS.PC_CONFIGURATOR],
  [pathnames.PC_CONFIGURATOR_DETAIL]: [GROUPS.PC_CONFIGURATOR],
  [pathnames.PC_CONFIGURATOR_PART_DETAIL]: [GROUPS.PC_CONFIGURATOR],
  [pathnames.STOREFINDER]: [GROUPS.STORE_FINDER],
  [pathnames.STOREFINDER_DETAIL]: [GROUPS.STORE_FINDER],
  [pathnames.ACTIVATE]: [GROUPS.GIFT_CARD],
  [pathnames.BUDGETCARD]: [GROUPS.BUDGET_CARD],
  [pathnames.COMPARE]: [GROUPS.PRODUCT_COMPARE, GROUPS.REVIEWS, GROUPS.PC_CONFIGURATOR],
  [pathnames.CONTACT]: [GROUPS.CMS, GROUPS.BUDGET_CARD, GROUPS.CONTACT, GROUPS.PRIVACY],
  [pathnames.DEMODAYS]: [GROUPS.DEMO_DAYS],
  [pathnames.GIFTCARD]: [GROUPS.GIFT_CARD],
  [pathnames.LOGIN]: [GROUPS.LOGIN],
  [pathnames.REGISTER]: [GROUPS.CHECKOUT, GROUPS.CART_DELIVERY_FORM],
  [pathnames.REPAIRS]: [GROUPS.DELIVERY_SERVICE, GROUPS.ORDER, GROUPS.REPAIR_SERVICE, GROUPS.SERVICES, GROUPS.CMS],
  [pathnames.SEARCH]: [GROUPS.DELIVERY, GROUPS.HERO_HEADER, GROUPS.CATEGORY, GROUPS.PRODUCT_DETAIL, GROUPS.CART],
  [pathnames.SPARE_PARTS]: [GROUPS.SERVICES, GROUPS.PART_SERVICE, GROUPS.CMS],
  [pathnames.MY_ACCOUNT]: [GROUPS.MY_ACCOUNT],
  [pathnames.MY_ACCOUNT_INFORMATION]: [
    GROUPS.MY_ACCOUNT,
    GROUPS.ORDER,
    GROUPS.ORDER_STATUS,
    GROUPS.BASKET_OVERVIEW,
    GROUPS.SERVICES,
    GROUPS.CHECKOUT,
    GROUPS.PC_CONFIGURATOR,
  ],
  [pathnames.MY_ACCOUNT_ORDERS]: [
    GROUPS.MY_ACCOUNT,
    GROUPS.ORDER,
    GROUPS.ORDER_STATUS,
    GROUPS.BASKET_OVERVIEW,
    GROUPS.SERVICES,
    GROUPS.CHECKOUT,
    GROUPS.PC_CONFIGURATOR,
  ],
  [pathnames.MY_ACCOUNT_ORDERS_DETAIL]: [
    GROUPS.MY_ACCOUNT,
    GROUPS.ORDER,
    GROUPS.ORDER_STATUS,
    GROUPS.BASKET_OVERVIEW,
    GROUPS.SERVICES,
    GROUPS.CHECKOUT,
    GROUPS.PC_CONFIGURATOR,
    GROUPS.PRODUCT_DETAIL,
  ],
  [pathnames.MY_ACCOUNT_ADDRESSES]: [
    GROUPS.MY_ACCOUNT,
    GROUPS.ORDER,
    GROUPS.ORDER_STATUS,
    GROUPS.BASKET_OVERVIEW,
    GROUPS.SERVICES,
    GROUPS.CHECKOUT,
    GROUPS.PC_CONFIGURATOR,
  ],
  [pathnames.MY_ACCOUNT_CARDS]: [
    GROUPS.MY_ACCOUNT,
    GROUPS.ORDER,
    GROUPS.ORDER_STATUS,
    GROUPS.BASKET_OVERVIEW,
    GROUPS.SERVICES,
    GROUPS.CHECKOUT,
    GROUPS.PC_CONFIGURATOR,
  ],
  [pathnames.MY_ACCOUNT_PREFERENCES]: [
    GROUPS.MY_ACCOUNT,
    GROUPS.ORDER,
    GROUPS.ORDER_STATUS,
    GROUPS.BASKET_OVERVIEW,
    GROUPS.SERVICES,
    GROUPS.CHECKOUT,
    GROUPS.PC_CONFIGURATOR,
    GROUPS.DELIVERY,
  ],
  [pathnames.MY_ACCOUNT_CONFIGURATIONS]: [
    GROUPS.MY_ACCOUNT,
    GROUPS.ORDER,
    GROUPS.ORDER_STATUS,
    GROUPS.BASKET_OVERVIEW,
    GROUPS.SERVICES,
    GROUPS.CHECKOUT,
    GROUPS.PC_CONFIGURATOR,
  ],
  [pathnames.MANUALS]: [GROUPS.MANUALS],
  [pathnames.SD_WORKS_CONFIRM]: [GROUPS.CHECKOUT],
  [pathnames.CASHBACK]: [GROUPS.CASHBACK, GROUPS.CATEGORY],
};

const getDelocalizedPathname = (pathname: string, locale: string): Pathname => {
  const pathnameIsNotLocalized = Object.values(pathnames).includes(pathname as Pathname);
  if (pathnameIsNotLocalized) return pathname as Pathname;
  const delocalizedPathname = delocalizedPathnames[pathname as Pathname]?.[locale];
  return delocalizedPathname || (pathname as Pathname);
};

const getGroupRouteMapping = (pathname: string, locale: string): string[] => {
  const delocalizedPathname = getDelocalizedPathname(pathname, locale);
  const groups = groupRouteMapping?.[delocalizedPathname] || [];
  return Array.from(new Set([...groups, ...DEFAULT_GROUPS]));
};

export default getGroupRouteMapping;
